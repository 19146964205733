.about {
  font-family: var(--fontFamily);
  padding: 30px 0;
}

.about h2 {
  color: #000000;
  font-family: var(--fontFamilyTwo);
  font-weight: 400;
  font-size: 30px;
}
.about h1 {
  color: #000000;
  font-family: var(--fontFamilyTwo);
  font-weight: 400;
  font-size: 34px;
  text-align: center;
}
.about p {
  line-height: 28px;
  text-align: center;
  font-size: 18px;
}
.about_img {
  max-width: 600px;
  width: 100%;
  margin-left: 16%;
}
.specialization_img {
  max-width: 600px;
  width: 100%;
  margin: auto;
}
.about__join {
  margin-bottom: 70px;
}
.about__technology,
.about__experience {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.about__specialization {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.join_img {
  max-width: 625px;
  margin: auto;
}
.technology_img {
  width: 100%;
  min-width: 409px;
}
.experience_img {
  min-width: 325px;
  margin-bottom: 100px;
}
/* .friendly_cont{
    margin-top: 160px;
} */
.about__team {
  margin-bottom: 50px;
}
.team_img {
  margin-top: 40px;
}
.about img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1600px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 32px;
  }

  .about p {
    line-height: 28px;
    text-align: center;
    font-size: 22px;
  }
}
@media (max-width: 1444px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 32px;
  }

  .about p {
    line-height: 28px;
    text-align: center;
    font-size: 20px;
  }
}
@media (max-width: 1340px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 30px;
  }

  .about p {
    line-height: 28px;
    text-align: center;
    font-size: 18px;
  }
}
@media (max-width: 1240px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 28px;
  }

  .about p {
    line-height: 28px;
    text-align: center;
    font-size: 16px;
  }
  .about_img {
    margin-left: 13%;
  }
}
@media (max-width: 1024px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 25px;
  }
  .about_img {
    max-width: 400px;
  }

  .about p {
    line-height: 28px;
    text-align: center;
    font-size: 15px;
  }
}
@media (max-width: 769px) {
  .about h2 {
    color: #000000;
    font-family: var(--fontFamilyTwo);
    font-weight: 400;
    font-size: 24px;
  }
  .about__technology,
  .about__experience {
    flex-direction: column-reverse;
    align-items: self-start;
  }
  .about__experience {
    flex-direction: column;
    align-items: self-end;
  }
  .friendly_cont {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .technology_img {
    width: 100%;
    min-width: 150px;
    max-width: 150px;
    margin: auto;
  }
  .experience_img {
    min-width: 150px;
    margin-bottom: 10px;
    max-width: 150px;
  }
  .about_img {
    max-width: 300px;
    margin: auto;
  }

  .about p {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .about__specialization {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
