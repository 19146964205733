.auth-wrapper {
  width: calc(100% - 30px);
  max-width: 460px;
  margin: 50px auto 20px;
  padding: 25px;
  background: #fff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
}
.auth-wrapper.reg-to-bid-wrapper {
  width: 100%;
  max-width: unset;
}
.auth-title {
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #9faebb;
  margin-top: 10px;
  font-family: "Avenir-Medium";
}

.auth-sub-title {
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  margin: 10px 0 15px;
}

.auth-container-box {
  padding: 19px;
  margin: 25px auto;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.auth-container-box .or-wrap::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 88%;
  background: #00173926;
}

.flex-wh-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.or-wrap {
  width: 100px;
  padding-left: 0;
  margin-right: 15px;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}

.auth-container-box .or-wrap span {
  background: #001739;
  padding: 12px 0px;
  border-radius: 100%;
  color: #fff;
  font-size: 13px;
  width: 48px;
  height: 46px;
  line-height: 23px;
  text-align: center;
}

.auth-form-wrapper {
  flex: 1;
}

.auth-container-box.fp-form {
  max-width: 420px;
}

.auth-form-wrapper .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}

.auth-form-wrapper .MuiIconButton-root {
  padding: 0;
}

.auth-form-wrapper .MuiListItem-gutters {
  padding: 0 0 0 10px;
}

.rm-fpassword {
  position: absolute;
  right: 0;
  top: -4px;
}

.modalViewChange .titleSign {
  text-align: center;
  font-size: 26px;
  line-height: 36px;
  font-weight: 800;
  color: var(--secColor);
}

.modalViewChange .auth-bottom {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 30px;
}

.modalViewChange .auth-bottom a {
  font-size: 16px;
  font-weight: 800;
  color: var(--primColor);
}

.modalViewChange .verticle-line {
  background: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.510417) 2.31%,
    #dbdee0 34.25%,
    #dbdee0 52.94%,
    #dbdee0 73.07%,
    rgba(217, 217, 217, 0) 100%
  );
  height: 1px;
  margin-top: 35px;
  margin-bottom: 35px;
  position: relative;
}

.modalViewChange .verticle-line::after {
  content: "Or";
  position: absolute;
  top: -11px;
  background-color: #ffffff;
  right: 0;
  left: 0;
  margin: auto;
  color: #9faebb;
  font-size: 14px;
  width: 35px;
  text-align: center;
}

.modalViewChange .socialAuth {
  width: 100%;
  height: 38px;
  border: 1px solid #c8cfd3;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  line-height: 1;
  color: #000000;
  font-weight: 600;
}
.modalViewChange .socialAuth:hover {
  color: var(--primColor);
}

.modalViewChange .socialAuth span {
  display: block;
}

.modalViewChange .socialAuth span.imgCont {
  width: 20px;
  height: 20px;
}

.modalViewChange .socialAuth span.imgCont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modalViewChange .forgotPass .rm-fpassword {
  color: var(--primColor);
  right: auto;
  left: 0;
}

.modalViewChange .forgotPass .rm-fpassword a {
  color: var(--primColor);
}
.modalViewChange .MuiCheckbox-root {
  background: #dde6ea !important;
  padding: 4px;
  margin: 7px 0;
}
.modalViewChange .auth-bottom a.changeColor {
  color: #134663 !important;
}
.socialAuthCont .d-inline-block {
  min-width: 403px;
  margin-left: -13px;
}
.socialAuthCont p span.mobn {
  font-weight: 500;
  display: inline-block;
}
.socialAuthCont p {
  line-height: 22px;
}
.modalViewChange .MuiFormControlLabel-root {
  align-items: start;
  margin: 0;
}

.modalViewChange .forgotPass {
  position: relative;
  margin-top: 15px;
  padding-bottom: 30px;
}

.hc-input .MuiOutlinedInput-input {
  margin-top: -8px;
}

.dl-link {
  margin: 0 auto 20px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--primColor);
  display: block;
  width: 100px;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input::-webkit-input-placeholder {
  opacity: 1 !important;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input::-moz-placeholder {
  opacity: 1 !important;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input:-ms-input-placeholder {
  opacity: 1 !important;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input::-ms-input-placeholder {
  opacity: 1 !important;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input:focus::-webkit-input-placeholder {
  opacity: 1;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input:focus::-moz-placeholder {
  opacity: 1;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input:focus:-ms-input-placeholder {
  opacity: 1;
  color: #9faebb;
}

.main-container
  label[data-shrink="false"]
  + .MuiInputBase-formControl
  .MuiInputBase-input:focus::-ms-input-placeholder {
  opacity: 1;
  color: #9faebb;
}

.expiration {
  display: flex;
  max-width: 210px;
  justify-content: flex-start;
}

.expiration > div {
  width: 75px;
}

.expiration > div + div {
  margin-top: 27px;
  margin-right: 20px;
}

.expiration .MuiInputBase-root {
  width: 55px;
}

.rtb-form-wrapper form .form-group {
  margin-top: 0 !important;
}

.rtb-form-wrapper {
  max-width: 372px;
  margin: 0 auto;
}

.choose-file {
  position: relative;
  width: 100%;
  max-width: 146px;
  margin: 0 auto;
}

.choose-file input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.choose-file label {
  width: 146px;
  height: 40px;
  background: var(--primColor);
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  text-align: center;
  color: #fff;
  font-weight: 800;
  border-radius: 4px;
}

.rtb-form-wrapper .MuiListItem-gutters {
  font-size: 14px;
  line-height: 24px;
  color: #595959;
}

a.click-here {
  color: #595959;
  text-decoration: underline;
}

.rtb-form-wrapper .primButton {
  max-width: 266px;
  margin: 25px auto;
}

.container.auth-container {
  max-width: 1000px;
}

.auth-layout-logo {
  width: 150px;
}
.modalViewChange .formContainer {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.modalViewChange .secBtn .primButton button {
  background-color: var(--secColor);
  text-transform: uppercase;
  border: 1px solid var(--secColor);
}
.modalViewChange .secBtn .primButton button:hover {
  background-color: #ffffff;
  border: 1px solid var(--secColor);
  color: var(--secColor);
}
.modalViewChange .makeStyles-root-15 .MuiInputLabel-outlined {
  font-family: "Poppins";
  font-size: 14px;
  color: #595959;
}
.modalViewChange .secBtn.btnCust .primButton button {
  background-color: #ffffff;
  color: #000000;
  line-height: 27px;
}
.modalViewChange h4.titleBid {
  font-size: 18px;
  font-weight: 500;
}

/* custom google login button css */
.custom-google-button {
  width: 100%;
  height: 38px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #c8cfd3;
  color: var(--primColor);
}

/* facebook login button css */

.my-facebook-button-class img {
  object-fit: contain;
  width: 20px;
  height: 20px;
}

.my-facebook-button-class {
  width: 100%;
  height: 38px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #c8cfd3;
  color: var(--primColor);
}

/* Apple login button css */

.apple-auth-btn {
  width: 100%;
  height: 38px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #c8cfd3;
  color: var(--primColor);
}

/* Responsive */

@media (max-height: 767px) {
  .modalViewChange .verticle-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .modalViewChange .formContainer {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .auth-wrapper {
    padding: 15px;
    margin: 20px auto;
  }

  .expiration > div {
    width: 60px;
  }
}

@media (max-width: 479px) {
  .auth-bottom a {
    display: inline-block;
    clear: both;
  }
}

@media (max-width: 600px) {
  .expiration > div + div {
    margin-top: 27px;
  }
}
