.file-upload-reg .uploaded-files p {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}
.update .loading {
  width: 20px !important;
  height: 20px !important;
  margin: 0 0.5rem;
}

.update .loading-non-hover {
  color: white;
}

.update .loading-hover {
  color: var(--primColor);
}
.register-load .primButton .MuiButtonBase-root {
  border: 1px solid var(--primColor);
  box-shadow: 0px 4px 12px rgba(var(--primColor), 20%);
  border-radius: 3px;
  background-color: #fff;
  color: var(--primColor);
}

.edit-bidder-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.signup .auth-title span {
  text-transform: initial;
}

.reg-bid-success-wrp {
  background-color: transparent !important;
  box-shadow: none !important;
}
.back-to-prop {
  background: #fff;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 7%);
}
.reg-bid-title {
  font-size: 24px;
}
.success-redir {
  padding: 25px;
}
.reg-bid-prop {
  margin: 15px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
}

.success-redir {
  text-align: center;
}

.success-redir .primButton .MuiButton-label {
  text-transform: initial;
}

.reg-bid-prop .prop-img {
  width: 150px;
  height: 150px;
}
.newBidWrap {
  max-width: 600px;
}
.reg-bid-prop .addressLine1 {
  margin: 0 5px;
  padding: 15px;
  font-size: 18px;
}
.bidding-check-box.checkboxWithContent b {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fontFamilyTwo);
}
.reg-bid-form {
  padding: 15px;
}

.priv-policy {
  background-color: #fafafa;
  padding: 10px;
}

.priv-policy p {
  margin: 0;
}
.reg-bid-files {
  padding: 10px;
}
.typeof-finance {
  margin: 10px 0;
}

.typeof-finance .finance-checkboxes,
.rtg-agent .finance-checkboxes {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

/* .typeof-finance .finance-checkboxes div,
.rtg-agent .finance-checkboxes div {
  /* width: 100%; */
/* margin: 0 0.5rem;
  max-width: 100%;
  min-width: 25%; */
/* } */

.typeof-finance .MuiListItem-gutters,
.rtg-agent .MuiListItem-gutters {
  font-size: 14px;
  line-height: 24px;
  color: #595959;
}
.typeof-finance .MuiIconButton-root,
.rtg-agent .MuiIconButton-root {
  padding: 0;
}
.rtb-contact-info {
  margin: 10px 0;
}
.rtg-agent p,
.rtg-agent .form-group {
  margin: 0 !important;
}
.back-to-list {
  color: var(--primColor);
  cursor: pointer;
}

.uploaded-files-list {
  margin: 10px 0;
}
.no-file {
  margin: 0 auto;
  width: fit-content;
}

.finance-checkboxes .MuiFormGroup-root.MuiFormGroup-row {
  width: auto !important;
  min-width: 110px !important;
  max-width: 100% !important;
}
.finance-checkboxes .MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  width: auto;
  max-width: -webkit-fill-available;
  vertical-align: middle;
  min-width: 120px;
}
.typeof-finance .finance-checkboxes div .MuiButtonBase-root {
  width: auto;
  padding-right: 0;
}
.uploaded-files p {
  width: 315px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.dropzone div {
  width: 100%;
  height: 100px;
  border: 1px dashed black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 8px;
}

.dropzone div .plus-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100%;
  background: var(--primColor);
  color: #fff;
  padding-top: 4px;
}

.dropzone div .browse-text {
  color: var(--primColor);
  text-decoration: underline;
  cursor: pointer;
}

.file-upload-reg p {
  text-align: left;
}
@media (max-width: 600px) {
  .reg-to-bid-wrapper .rtg-agent .finance-checkboxes {
    flex-wrap: nowrap;
  }

  .reg-to-bid-wrapper .uploaded-files p {
    width: 200px;
  }

  /* .reg-to-bid-wrapper .rtb-contact-info p,
  .reg-to-bid-wrapper .priv-policy p {
    text-align: justify;
  } */

  .reg-to-bid-wrapper .rtb-contact-info p br {
    display: none;
  }

  .reg-to-bid-wrapper .priv-policy p br {
    display: none;
  }
}

.reg-bid-files .drgFlsWrpr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg-bid-files .drgFlsWrpr span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.check-label .MuiListItem-button {
  white-space: normal;
}
