.profile-card-wrapper .makeStyles-root-9 .MuiTextField-root {
  max-width: 100%;
}

.secBtn .primButton button {
  background-color: var(--secColor);
  text-transform: uppercase;
  border: 1px solid var(--secColor);
  height: auto;
}

.secBtn .primButton button:hover {
  background-color: #ffffff;
  border: 1px solid var(--secColor);
  color: var(--secColor);
}
.profile-card-wrapper h3.accTitle {
  font-size: 24px;
  color: var(--secColor);
}
.profile-card-wrapper h4.title {
  font-size: 18px;
  padding-top: 20px;
  color: var(--secColor);
}
.profile-card-wrapper .Component-root-14 {
  padding: 0;
  margin-left: 10px;
}
.profile-card-wrapper .checkboxWithContent {
  position: relative;
  padding-bottom: 10px;
}
.profile-card-wrapper .MuiListItem-button {
  padding: 5px 15px;
  font-size: 14px;
  color: var(--secColor);
}
.profile-card-wrapper .checkboxWithContent span.subti {
  position: absolute;
  left: 30px;
  right: auto;
  font-size: 14px;
  top: 30px;
  bottom: auto;
}
.checkboxWithContent .MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
  margin-left: 10px;
}

.accountCheck .MuiFormControlLabel-root {
  margin-left: 0;
}

.accountCheck .MuiFormControlLabel-root .MuiTypography-root {
  margin-left: 5px;
}

.accountCheck .MuiFormControlLabel-root .MuiButtonBase-root.MuiListItem-button {
  color: #000;
}

.accountCheck .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0;
  font-weight: 500;
}
