footer ul {
  padding-left: 0px;
  list-style: none;
}
footer .container {
  max-width: 1280px;
}

footer ul li {
  list-style-type: none;
  padding: 4px 0px;
  color: #a1aeb7;
  font-size: 14px;
}

footer a {
  text-decoration: none;
  text-align: left;
  color: #fff;
  font-weight: 500;
  font-family: var(--fontFamily);
}

footer ul li > a:hover,
footer ul li > a:focus {
  color: var(--primColor) !important;
  text-decoration: none;
}

.footer {
  padding: 15px 0 25px;
  color: #fff;
  background-color: #000;
  font-family: var(--fontFamily);
}
footer .list-inline {
  line-height: 28px;
}
footer .list-inline li a {
  font-size: 16px;
  text-transform: capitalize;
}

.footer-bottom {
  background: var(--primColor);
  padding: 13px 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
footer .contactServ .list-inline {
  display: flex;
  gap: 20px;
}
footer .contactServ h5.title {
  font-size: 18px;
  font-weight: 600;
}
footer .contactServ p {
  font-size: 16px;
  color: #8b8b8b;
}
.footer .contactServ .list-inline li a {
  width: 25px;
  height: 25px;
  display: block;
}
.footer .contactServ .list-inline li {
  width: 30px;
  height: 38px;
  margin: 0 !important;
}
/* footer .footerView {
  display: grid;
  grid-template-columns: auto auto;
} */
footer .footerView .footLogoCont {
  max-width: 224px;
  height: 133px;
}

.footerTwo a {
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerTwo a:hover {
  color: var(--primColor);
  text-decoration: none;
}
.footerOne {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  border-bottom: 1px solid #fff;
}
footer .footerView .footLogoCont img {
  max-width: 224px;
  height: 133px;
  margin: 0;
  object-fit: contain;
}

.footer-bottom .list-inline {
  margin: 0;
}

.footer-bottom .list-inline li {
  margin-right: 0;
}

.footer-bottom .list-inline li + li {
  margin-left: 45px;
}

.footer h4 {
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #505d68;
  font-family: "Avenir-Medium";
}

.nl-wrapper p {
  padding: 10px 0;
  margin: 0;
  color: #a1aeb7;
}

.copy-right {
  padding-top: 4px;
}

.subscribe-wrapper {
  position: relative;
}

.subscribe-wrapper .form-control {
  color: #c2d1d9;
  font-size: 14px;
  height: 40px;
  padding-right: 105px;
}

.subscribe-wrapper .MuiButton-containedPrimary {
  position: absolute;
  height: 40px;
  width: 100px;
  font-size: 13px;
  font-weight: normal;
  right: 0;
  top: 0;
}
.terms-header {
  display: flex;
  justify-content: space-between;
}
/* .news-letter-wrapper a {
  text-decoration: underline;
} */

.footer .footLogo {
  height: 80px;
  width: auto;
  object-fit: contain;
}

.social-icons-list li a {
  font-size: 20px;
}
.footer .container .footerView .footerOne .naaLogoMobView {
  display: none;
}
.footer .container .footerView .footerOne .naaLogo {
  display: block;
}
.footer .container .footerView .footerTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .container .footerTwoMobview {
  display: none;
}
@media (min-width: 992px) {
  .footer .row > div {
    -ms-flex: 0 0 18%;
    flex: 0 0 18%;
    max-width: 18%;
  }

  .footer .row > div:last-child {
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    max-width: 28%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-letter-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .nl-wrapper p {
    padding: 0;
  }

  .subscribe-wrapper {
    position: relative;
    width: calc(100% - 250px);
  }
}

@media (max-width: 767px) {
  footer .footerView .footLogoCont img {
    width: 80%;
    height: auto;
  }
  .footer {
    padding: 15px 0;
  }
  .footer .footLogo {
    height: 50px;
    width: 100%;
  }
  footer .footerView {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .footer-bottom .list-inline li + li {
    margin-left: 15px;
  }

  .footer-bottom {
    text-align: center;
  }

  .social-icons-xs a {
    display: inline-block;
    vertical-align: middle;
    color: #000 !important;
    font-size: 22px;
  }

  .social-icons-xs a + a {
    margin-left: 18px;
  }

  footer ul li.social-icons-xs {
    padding: 0;
    margin-top: 14px;
  }
  .footer .container .footerView .footerOne .naaLogoMobView {
    display: block;
  }
  .footer .container .footerView .footerOne .naaLogo {
    display: none;
  }
  .footer .container .footerView .footerOne {
    width: 94vw;
    padding: 10px 0px;
    justify-content: space-between;
  }
  .footer .container .footerView .footerTwo {
    display: none;
  }
  .footer .container .footerTwoMobview {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    padding-top: 12px;
    border-top: 1px solid white;
  }
  .footer .container .footerTwoMobview a {
    text-align: center;
  }
  .footer .footerView img {
    width: 80px;
    height: 60px;
  }
  .footer .footerView .footLogoCont {
    width: 120px;
    height: auto;
  }
  .footer .footerView .footLogoCont img {
    width: 120px;
  }
}

@media (max-width: 479px) {
  footer .contactServ .list-inline {
    grid-gap: 10px;
    gap: 10px;
  }
}
