* {
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: auto;
  -webkit-tap-highlight-color: transparent;
  outline-color: unset !important;
  outline-style: unset !important;
  -webkit-tap-highlight-color: transparent;
  outline-width: 0px;
}
:root {
  --fontFamily: "avenir_lt_std55_roman";
  --fontFamilyTwo: "arkhipregular";
}
@font-face {
  font-family: "avenir_lt_std95_black";
  src:
    url("/src/fonts/Avenir/avenirltstd-black-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std95_black_oblique";
  src:
    url("/src/fonts/Avenir/avenirltstd-blackoblique-webfont.woff2")
      format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-blackoblique-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std45_book";
  src:
    url("/src/fonts/Avenir/avenirltstd-book-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std45_book_oblique";
  src:
    url("/src/fonts/Avenir/avenirltstd-bookoblique-webfont.woff2")
      format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-bookoblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std85_heavy";
  src:
    url("/src/fonts/Avenir/avenirltstd-heavy-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std85_heavy_oblique";
  src:
    url("/src/fonts/Avenir/avenirltstd-heavyoblique-webfont.woff2")
      format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-heavyoblique-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std35_light";
  src:
    url("/src/fonts/Avenir/avenirltstd-light-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std35_light_oblique";
  src:
    url("/src/fonts/Avenir/avenirltstd-lightoblique-webfont.woff2")
      format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-lightoblique-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std65_medium";
  src:
    url("/src/fonts/Avenir/avenirltstd-medium-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std65MdOb";
  src:
    url("/src/fonts/Avenir/avenirltstd-mediumoblique-webfont.woff2")
      format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-mediumoblique-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std55_oblique";
  src:
    url("/src/fonts/Avenir/avenirltstd-oblique-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-oblique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "avenir_lt_std55_roman";
  src:
    url("/src/fonts/Avenir/avenirltstd-roman-webfont.woff2") format("woff2"),
    url("/src/fonts/Avenir/avenirltstd-roman-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "arkhipregular";
  src:
    url("/src/fonts/Arkhip/arkhip_font-webfont.woff2") format("woff2"),
    url("/src/fonts/Arkhip/arkhip_font-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "arkhipregular";
  src:
    url("/src/fonts/Arkhip/arkhip_font-webfont.woff2") format("woff2"),
    url("/src/fonts/Arkhip/arkhip_font-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.MuiButton-label {
  z-index: 1;
}

.pdtVewWrpr .vwImWrpr,
.pdtVewWrpr .vwImWrpr img {
  max-width: 128px;
  width: 100%;
  height: 128px;
  border-radius: 5px;
}

.pdtVewWrpr .vwImWrpr {
  position: relative;
  margin: 5px 0;
}

.pdtVewWrpr .vwImWrpr .clrBtn {
  position: absolute;
  padding: 0px;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: black;
  color: white;
  top: -5px;
  right: -5px;
}

.auth-wrapper .auth-form-wrapper .RadioBox .MuiFormControlLabel-root {
  width: 100%;
  margin-top: 5px;
}

.auth-wrapper
  .auth-form-wrapper
  .RadioBox
  .MuiFormControlLabel-root
  .MuiTypography-root {
  padding-left: 5px;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #cfcfcf inset !important;
}

.MuiOutlinedInput-root input {
  height: 45px !important;
  padding: 0 15px !important;
}

.van-link.ntlgn {
  font-size: 14px;
}

.myNtsHdr {
  position: relative;
}

.adNtsIcon {
  position: absolute;
  right: 0;
  color: var(--primColor);
}

.mntsDesc {
  width: 100%;
  max-width: 100%;
  resize: none;
  min-height: 150px;
}

.ttlCltdVlue {
  font-weight: bold;
  font-size: 22px;
  color: var(--primColor);
}

.avatarComment {
  width: 26px;
  height: 26px;
  border-radius: 50px;
}

.mapboxgl-map {
  height: 100%;
}

.marker {
  background-image: "/images/marker.png";
  width: 500px;
  height: 500px;
  background-size: 100%;
}

.biddingModal .modal-dialog {
  margin: 0 auto !important;
  max-width: 750px !important;
}

.shrIcon {
  font-size: 18px;
  margin-right: 10px;
}

.shareIconLabel {
  font-size: 16px;
  display: flex;
  text-transform: capitalize;
  font-family: "Avenir-Medium";
  text-align: center;
  font-weight: 500;
  justify-content: center;
}

.shrBtn .gsl-text {
  text-decoration: underline;
  text-transform: capitalize;
}

.shrIcon.fa-facebook,
.fb-lbl {
  color: #4267b2;
}

.shrIcon.fa-linkedin,
.lin-lbl {
  color: #0e76a8;
}

.shrIcon.fa-twitter,
.tw-lbl {
  color: #1da1f2;
}

.shrIcon small {
  font-size: 12px;
  /* margin-left: 10px; */
  text-transform: uppercase;
}

.shrBtn {
  margin: 10px 4px;
  min-width: 100px;
  padding: 0 !important;
}

.react-share__ShareButton {
  width: 100%;
  height: 100%;
  padding: 10px !important;
}

button.react-share__ShareButton {
  outline: none;
}

.mapValueInput .MuiInputBase-root {
  padding: 0 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.prflCmntWrpr {
  position: relative;
}

.prflCmntWrpr .cnstrtFlg .MuiButton-label {
  text-transform: initial !important;
  font-family: "Avenir-Medium";
}

.prflCmntWrpr .cnstrtFlg {
  position: absolute;
  bottom: 12px;
  right: 15px;
}

.prflCmntWrpr .cnstrtFlg:focus {
  outline: none;
}

.prflCmntWrpr .rplyBtnWrp {
  position: absolute;
  right: 22px;
  top: 19px;
  font-size: 13px;
}

a.reply-link:hover {
  color: #6d72ee;
}

.crdNmbrWtIcon {
  position: relative;
}

.crdNmbrWtIcon .crdIcons {
  position: absolute;
  right: 0;
  top: -5px;
  padding-right: 15px;
}

.crdNmbrWtIcon .crdIcons img {
  height: 25px;
  width: auto;
}

.network-issue {
  z-index: 1001;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: fixed;
  top: 70px;
  width: 100%;
}

.network-reload {
  cursor: pointer;
  text-decoration: underline;
}

.buyOfferPopupWrpr .MuiFormGroup-root .MuiIconButton-root {
  padding: 0;
}

.buyOfferPopupWrpr .MuiFormControlLabel-root {
  margin: 0;
}

.buyOfferPopupWrpr .MuiFormGroup-root {
  justify-content: center;
}

.loader-wrapper {
  position: relative;
  width: 100%;
  height: 140px;
}

.loader-wrapper .loader-context {
  position: absolute;
  background: transparent;
}

@media (max-width: 767px) {
  .MuiOutlinedInput-root input {
    height: 38px !important;
  }

  .mntsDesc {
    width: 100%;
    max-width: 270px;
  }

  .biddingModal .modal-dialog {
    margin-top: 40px !important;
  }

  .network-issue {
    position: fixed;
  }
  .crdNmbrWtIcon .crdIcons img {
    height: 19px;
    width: auto;
  }
}

@media (max-width: 479px) {
  .biddingModal .modal-dialog {
    margin-top: 0 !important;
  }

  .custom-modal {
    padding: 0;
  }

  .custom-modal .modal-content {
    max-height: 100vh;
    overflow: auto;
  }

  .biddingModal .submitBidFormHeader {
    padding: 0 2px;
    text-align: left;
    margin-bottom: 15px;
  }

  .biddingModal .submitBidFormHeader .pvd-tc,
  .biddingModal .submitBidFormHeader .pl-text {
    text-align: left !important;
  }
}
