.primButton.small .MuiButton-containedPrimary {
  height: 40px !important;
}

.dialog-tag {
  border: var(--primColor) 1px solid;
  padding: 5px;
  border-radius: 20px;
  background-color: rgb(249, 245, 255);
}
.rply-seller {
  width: max-content;
  margin-bottom: 10px;
}

.askQuesModal .MuiDialog-paper {
  width: 100%;
}
.replyComment .MuiDialog-paperWidthSm {
  width: 100%;
  font-weight: "Poppins";
}
.replyComment .MuiDialog-paperWidthSm .MuiTypography-h6 {
  display: flex;
  justify-content: space-between;
}
.replyComment .MuiDialog-paperWidthSm .primButton {
  padding-right: 15px;
}
.replyComment .MuiDialog-paperWidthSm .MuiTypography-colorTextSecondary {
  margin-bottom: 0;
}
.askQuesModal .mntsDesc {
  max-width: 100%;
}

.askQuesModal .MuiDialogActions-root {
  padding: 8px 24px;
}
