.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  position: relative;
}

.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper .clsButton {
  position: absolute;
  top: 6px;
  right: 6px;
}

.cstmDialogWrapper .MuiPaper-root.MuiDialog-paper .clsButtonText {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--primColor);
  cursor: pointer;
}
.cstmDialogWrapper.modalViewChange .MuiPaper-root.MuiDialog-paper {
  max-width: 460px;
}
.modalViewChan .pv-white-box.ki-box,
.modalViewChan .pv-white-box.ntFrmMble,
.modalViewChan .ntFrmMble,
.modalViewChan .about-sarea {
  display: none;
}
.modalViewChan .property-right .topRightBox {
  display: block;
}
.cstmDialogWrapper.modalViewChangeProfile .MuiPaper-root.MuiDialog-paper {
  max-width: 820px;
  font-size: 16px;
  padding: 25px;
}
.modalViewChangeProfile .MuicheckCounter .MuiRadio-colorPrimary.Mui-checked {
  color: var(--primColor);
}
.cstmDialogWrapper.beforePopup .MuiPaper-root.MuiDialog-paper {
  max-width: 430px;
}

.cstmDialogWrapper .modalTitle h4 {
  font-size: 20px;
  font-family: 800;
}
.modalViewChangeProfile.cstmDialogWrapper .modalTitle h4 {
  color: var(--primColor);
  font-size: 22px;
}
.savedModalAtions {
  gap: 20px;
}
.savedModalAtions .primButton {
  width: 100%;
  max-width: 130px;
}
.savedModalAtions .primButton button {
  height: 45px;
  font-size: 18px;
}
.savedDelete .MuiDialog-paper {
  max-width: 460px !important;
}
.savedDelete .MuiDialog-paper .modalTitle {
  margin-top: 30px;
  text-align: center;
}

@media (max-width: 479px) {
  .cstmDialogWrapper.modalViewChangeProfile .MuiPaper-root.MuiDialog-paper {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0 !important;
  }
}
