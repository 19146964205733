.contact-main-container {
  margin-bottom: 0;
  padding: 0 0 65px;
  min-height: calc(100vh - 150px);
}
.contact-banner img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  opacity: 0.6;
}
.privacyAlign h3 {
  color: var(--primColor);
}
h3 i {
  color: #373737;
  font-size: 17px;
}
h3 b {
  display: block;
  color: #000000;
  font-family: var(--fontFamily);
  font-size: 17px;
}
.error {
  color: red;
}
ol li,
ul li {
  font-size: 16px;
}
ol li h3,
ul li h3 {
  margin: 0;
  color: #000000;
  font-weight: 600;
  display: inline;
  font-size: 16px;
}
p a,
ol a {
  color: var(--primColor);
}
.contactform {
  max-width: 628px;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  background: #fff;
  padding: 35px 25px 25px;
  border-bottom: 5px solid #000;
  min-height: 100%;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
}
.cu-margin-125 {
  margin-top: -125px;
}
.contact-main-container .form-group {
  margin-top: 0px !important;
}
.cu-head {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  font-family: var(--fontFamilyTwo);
  color: #000;
  margin-bottom: 35px;
  text-transform: uppercase;
}
.privacyAlign {
  margin: 30px auto;
  max-width: 900px;
  width: 100%;
}
.privacyAlign h2 {
  font-size: 19px !important;
  text-align: left;
}
.privacyAlign h2,
.privacyAlign h5 {
  font-family: var(--fontFamilyTwo);
  font-size: 17px;
}
.contactform .form-control.textArea {
  height: 150px;
}
.contactform textarea {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid grey;
}
.contactform input {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid grey;
}
.scontact-us-wrap .btn {
  width: 160px;
}

.contactform input {
  margin-bottom: 5px;
}
.contactform .media {
  margin-bottom: 25px;
}
.max-368 {
  max-width: 368px;
}

.ru-head {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: start;
  color: #373737;
}

.ru-con {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: start;
  color: #646464 !important;
  margin-bottom: 0;
  display: block;
  font-weight: 500;
  width: 100%;
  word-break: break-all;
}

.contactform .media-left .material-icons {
  margin-inline-end: 20px;
  font-size: 24px;
}

.contactform .primButton {
  width: 200px;
  margin: 0 auto;
}

.contactform .customTextArea .MuiTextField-root .MuiInputBase-root,
.contactform .customTextArea .MuiTextField-root .MuiInputBase-root textarea {
  min-height: 150px !important;
}

@media (max-width: 600px) {
  .cu-head {
    font-size: 22px;
  }

  .cu-margin-125 {
    margin-top: -185px;
    margin-bottom: 30px;
  }

  .contactform.cfRt {
    margin-top: 20px;
  }
}
