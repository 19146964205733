.grid-card {
  height: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 14px rgb(0 0 0 / 6%);
  background: #fff;
  position: relative;
}

.image-gallery .grid-card-img {
}
.grid-card-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 198px;
  max-height: 198px;
  min-height: 198px;
}

.grid-card .card-body {
  padding: 5px 14px;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.grid-bottom {
  font-family: "Avenir-Book";
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  z-index: 110;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid-timer {
  height: 25px;
  border-radius: 3px;
  background: #00000082;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-align: left;
  color: #fff;
  padding: 2px 5px;
  float: right;
  width: 100%;
}

.sg-wrapper .grid-timer {
  text-align: center;
}

.gfn-wrap > * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.pink-back {
  background: #e50e47 !important;
}

.green-back {
  background: #10c26d !important;
}

.uc-label {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  color: #000;
  padding: 2px 5px;
  background: #fff;
  border-radius: 3px;
}

.upc-grid .grid-card-img {
  height: 250px;
}

.upc-grid .grid-bottom {
  bottom: 70px;
}

.upc-grid .schedule {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-radius: 0 0 3px 3px;
  padding: 10px;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #000;
}

.sc-links {
  display: flex;
  justify-content: space-between;
}

.sc-links a {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #9faebb !important;
  font-family: "Avenir-Book";
  font-weight: 600;
  margin-top: 5px;
}

.card-body .grid-bottom .itmPrc {
  font-family: "Avenir-Book";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 21px;
  margin-bottom: 4px;
}

.card-body .grid-bottom .itmAdrs {
  max-width: 229px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-body .grid-bottom .itmCty,
.card-body .grid-bottom .itmSpecs,
.card-body .grid-bottom .itmAdrs {
  font-family: "Avenir-Book";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}

.card-body .grid-bottom .itmSpecs,
.card-body .grid-bottom .itmAdrs {
  font-weight: 700;
  letter-spacing: 0.27px;
}

.upcmTtle {
  font-family: "Avenir-Book";
  font-weight: 600;
}

.grid-card .nf-list {
  position: absolute;
  width: 100%;
  top: 173px;
}

.grid-card .nf-list .favoriteCheck label {
  color: #fff;
}

.grid-card .nf-list a {
  font-size: 16px;
  line-height: 21px;
  color: #fff !important;
  margin: 0 5px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .card-body .grid-bottom .itmCty,
  .card-body .grid-bottom .itmSpecs,
  .card-body .grid-bottom .itmAdrs {
    font-size: 11px;
  }
}
