.bannerImgCnt {
  width: 100%;
  height: 80vh;
  position: relative;
}

.bannerImgCnt .bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homePdCont {
  padding-top: 60px;
  /* max-width: 1320px; */
  margin: auto;
  padding-bottom: 20px;
}
.home-container {
  background-color: #ffffff;
}
.bannerImgCnt h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Avenir-Medium";
  color: #fff;
  width: 100%;
  max-width: 90%;
  font-size: 42px;
  line-height: 50px;
  margin: 0 auto;
}

.homeHiw {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 90px auto;
}

.homeHiw .hiwCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.homeHiw .hiwCard img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.homeHiw .hiwCard h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.homeHiw .hiwCard p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 25px;
}

.homeHiw .hiwCard .primButton button {
  width: 180px;
}

.homeHiw .hiwCard .primButton button .MuiButton-label {
  text-transform: initial;
}

.marketReport {
  text-align: center;
  background: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
}

.marketReport h3 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

.marketReport p {
  font-size: 24px;
  margin-bottom: 0;
}

.marketReport small {
  font-size: 16px;
  margin-bottom: 0;
}

.marketReport .primButton {
  width: 200px;
  margin: 30px auto 0;
}

.marketReport .primButton button .MuiButton-label {
  text-transform: initial;
}

.transactBanner {
  background: url("../../../assets/images/transactBanner.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 90px 60px;
}

.transactBanner h3 {
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 32px;
  margin-bottom: 30px;
}

.transactBanner h3:last-child {
  margin-bottom: 0;
}

.comparisonCnt {
  padding-top: 90px;
  padding-bottom: 90px;
}

.comparisonCnt .comparisonInner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.comparisonCnt .comparisonInner ul {
  margin: 0;
}

.comparisonCnt .comparisonInner ul li {
  font-size: 18px;
  margin-bottom: 5px;
}

.comparisonCnt .comparisonInner ul li:first-child {
  list-style: none;
  text-align: center;
  font-family: "Avenir-Medium";
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.comparisonCnt .compAction {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 30px;
}

.comparisonCnt > h3 {
  font-family: "Poppins";
  font-size: 34px;
  text-align: center;
}

.comparisonCnt > p {
  font-family: "Avenir-Medium";
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}
@media (min-width: 552px) {
  .hidden-desktop {
    display: none;
  }
}

@media (max-width: 767px) {
  .homeHiw {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .homePdCont {
    padding: 0 15px;
  }
}

@media (max-width: 552px) {
  .homeHiw {
    display: grid;
    grid-template-columns: 1fr;
  }
  .comparisonCnt .suitable_comparison {
    display: grid;
    grid-template-columns: 1fr;
  }
  .hidden-mobile {
    display: none;
  }
  .comparisonCnt .compAction {
    display: grid;
    grid-template-columns: 1fr;
  }
}
