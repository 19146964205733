.faqAlign {
  margin: 50px auto;
  max-width: 900px;
  width: 100%;
  line-height: 1.6;
}
.faqAlign .descing {
  color: #767676;
}
h2 {
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  color: var(--primColor);
  text-align: center;
  font-weight: 800;
}
b {
  font-family: var(--fontFamilyTwo);
}
.allFaqTypes .MuiTab-root {
  border-radius: 30px;
  border: 2px solid #000;
  width: fit-content;
  min-width: 170px;
}
.allFaqTypes .MuiTab-root.Mui-selected {
  background-color: var(--primColor);
  color: #fff;
  border: 2px solid var(--primColor);
}
.allFaqTypes .MuiTabs-root {
  margin: 20px;
}
.allFaqTypes .MuiBox-root {
  margin-top: 25px;
}
.allFaqTypes .MuiAccordion-root {
  box-shadow: none;
}
.allFaqTypes .MuiAccordionSummary-content,
.allFaqTypes .MuiAccordionSummary-content.Mui-expanded {
  font-size: 18px;
  margin: 10px 0;
}
.allFaqTypes .MuiAccordionSummary-content.Mui-expanded {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.allFaqTypes .MuiAccordionSummary-root {
  min-height: 64px;
}
.allFaqTypes .MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px;
}
.allFaqTypes .MuiAccordionDetails-root {
  font-size: 16px;
}
.allFaqTypes .MuiTabs-flexContainer {
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}
.MuiTabs-indicator {
  background-color: unset !important;
}
h3 {
  margin: 0.5rem 0;
  font-size: 18px;
  font-weight: 600;
}
p {
  margin: 0 0 1rem;
  font-size: 16px;
}
@media (max-width: 768px) {
  .allFaqTypes .MuiTab-root {
    width: 100%;
  }
  .faqAlign h1 {
    font-size: 30px;
  }
  .faqAlign p {
    font-size: 14px;
  }
  .faqAlign {
    padding: 0 15px;
  }
  .allFaqTypes .MuiAccordionDetails-root {
    font-size: 14px;
  }
  .allFaqTypes .MuiAccordionSummary-content,
  .allFaqTypes .MuiAccordionSummary-content.Mui-expanded {
    font-size: 15px;
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
