.payment-links li a {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #9faebb;
  cursor: pointer;
}

.payment-links li a.active {
  color: var(--primColor);
}

.address-card {
  position: relative;
  width: 100%;
}

.address-card > button {
  width: 100%;
}

.address-card input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.ac-radio {
  width: 100%;
  height: 175px;
  border-radius: 5px;
  background: #fff;
  border: 2px dashed #b4baca;
  padding: 1.5rem 2rem;
  font-size: 1rem;
  line-height: 2rem;
  color: #788094;
}

.ac-radio h6 {
  font-weight: 500;
  color: #444d63;
}

.ac-radio .material-icons {
  position: absolute;
  color: #dbd9e2;
  right: 2rem;
  top: 1.5rem;
}

.address-card input[type="radio"]:checked ~ .ac-radio {
  background: #f9fffc;
  border-color: var(--primColor);
}

.address-card input[type="radio"]:checked ~ .ac-radio .material-icons {
  color: var(--primColor);
}

.delivery-type {
  width: 96px;
  height: 30px;
  border-radius: 15px;
  background: var(--primColor);
  float: right;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #fff;
  padding: 5px;
}

.ccard-wrap {
  height: 175px;
  border-radius: 5px;
  background: linear-gradient(#726e9e 0%, #042843 100%);
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 1.5rem;
  color: #fff;
  margin-right: 0;
}

.ccard-wrap .deleteCard {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #f9f9f973;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}

.card-num {
  padding: 10px 0;
}

.card-num span {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1;
  margin: 0.25rem 0;
}

.card-num span.text-hides {
  padding-top: 7px;
}

.card-num span + span {
  margin-left: 12px;
}

.fs-12 {
  font-size: 0.75rem;
}

@media (min-width: 768px) {
  .savedCardsSection > .form-group {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 320px;
  }
}

@media (max-width: 991px) {
  .ac-radio {
    padding: 20px 15px;
  }
}

@media (max-width: 479px) {
  .ccard-wrap {
    padding: 1.5rem 1rem;
  }
}
