.BannerSection {
  width: 100%;
  /* max-width: 1280px; */
  margin: auto;
  background-image: url("/src/assets/images/bannerBG.png");
  padding: 60px 0;
  flex-wrap: wrap;
  gap: 20px;
  background-size: cover;
}

.BannerSection h3 {
  font-size: 48px;
  line-height: 63px;
  padding-top: 15px;
  font-weight: 500;
  font-family: var(--fontFamilyTwo);
}

.BannerSection .h6 {
  font-size: 16px;
}

.BannerSection h6 .ti24 {
  font-weight: 600;
  color: var(--secColor);
}

.BannerSection h6 .tiHS {
  font-weight: 600;
  color: var(--primColor);
}

.BannerSection .Bannlft {
  max-width: 950px;
  text-align: center;
  width: 100%;
  border: 8px solid var(--primColor);
  padding: 50px 145px;
  border-top: none;
  position: relative;
  color: #fff;
}

.BannerSection .Bannlft::before {
  content: "";
  width: 150px;
  height: 8px;
  background-color: var(--primColor);
  position: absolute;
  top: 0;
  left: 0;
}

.BannerSection .Bannlft::after {
  content: "";
  width: 150px;
  height: 8px;
  background-color: var(--primColor);
  position: absolute;
  top: 0;
  right: 0;
}

.BannerSection .Bannlft p {
  font-size: 22px;
  font-weight: 400;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 30px;
}

.searchBar .MuiInputBase-root {
  border-radius: 40px;
}

.searchBar .MuiOutlinedInput-root input {
  height: 52px !important;
}

.searchBar .search-button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: var(--primColor);
  height: 52px;
  width: 52px;
  min-width: unset;
  color: #fff;
  cursor: pointer;
  z-index: 111;
}

.searchBar .search-button:hover {
  background-color: var(--primColor);

  color: white;
}

.BannerSection .Bannrgt {
  width: 48%;
  max-width: 420px;
}

.BannerSection .homeBtn .primButton button {
  max-width: 212px;
  border-radius: 40px;
  min-width: 212px;
  font-weight: 500;
  height: 60px;
}

.BannerSection .homeBtn .primButton button span {
  font-size: 16px;
}

.BannerSection .homeBtn .secButton button span {
  font-size: 16px;
}

.seeMoreBtn .primButton button {
  max-width: 147px;
  border-radius: 40px;
  min-width: 147px;
  font-weight: 500;
  /* background-color: var(--secColor); */
  height: 60px;
  /* border: 1px solid var(--secColor); */
}

.primButton button,
.secButton button {
  font-family: "Poppins";
}

.seeMoreBtn .primButton button span {
  font-size: 16px;
}

.BannerSection .homeBtn .secButton button {
  max-width: 212px;
  border-radius: 40px;
  min-width: 212px;
  height: 60px;
  font-weight: 500;
  border: 1px solid var(--primColor);
}

.BannerSection .Bannrgt .bannerCard {
  width: 100%;
  max-width: 386px;
  position: relative;
  height: 100%;
  min-height: 419px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-left: auto;
}

.BannerSection .bannerCard .timmer {
  padding-top: 10px;
  text-align: center;
  color: #ff0000;
  font-weight: 700;
  font-size: 22px;
}

.BannerSection .bannerCard .timmer span {
  color: #ff0000;
  font-weight: 700;
  font-size: 22px;
}

.BannerSection .Bannrgt .biddingComp {
  position: absolute;
  width: 100%;
  bottom: 20px;
  top: auto;
  max-width: 355px;
  left: 15px;
  right: auto;
  align-items: center;
}

.BannerSection .Bannrgt img {
  position: absolute;
  right: 20px;
  width: 100%;
  top: 30px;
}

.BannerSection .Bannrgt .bid .bidCount {
  color: var(--primColor);
}

.BannerSection .Bannrgt .bid {
  font-weight: 600;
  font-size: 18px;
}

.BannerSection .Bannrgt .bidBtn .primButton button {
  width: 100%;
  max-width: 165px;
  min-width: 165px;
  border-radius: 40px;
}

.titleSec {
  text-align: center;
  margin-top: 58px;
  margin-bottom: 60px;
}

.titleSec h2 {
  color: #000000;
  font-size: 30px;
  position: relative;
  font-family: var(--fontFamilyTwo);
  font-weight: 600;
  margin: 10px;
}

.titleSec h2::after {
  content: "";
  position: absolute;
  margin: auto;
  top: 48px;
  left: 0;
  right: 0;
  width: 150px;
  height: 4px;
  background-color: var(--primColor);
}

.howWork .partOne .card,
.howWork .partTwo .card {
  border: 2px solid #c4c4c4;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
  align-items: center;
}

.howWork .partOne .card p,
.howWork .partTwo .card p {
  color: #888888;
  font-size: 16px;
}

.howWork .partOne .card span,
.howWork .partTwo .card span {
  padding: 20px;
  font-size: 22px;
  border-radius: 30px;
  margin-bottom: 20px;
  height: 45px;
  width: 45px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.howWork .partOne .card h3,
.howWork .partTwo .card h3 {
  font-family: var(--fontFamilyTwo);
  font-size: 20px;
  color: #696969;
  margin-bottom: 15px;
}

.howWork .partOne,
.howWork .partTwo {
  display: grid;
  gap: 20px;
  grid-template-columns: 40% 60%;
}

.howWork .partOne {
  margin-bottom: 20px;
}

.upcomingCard .cardBox {
  padding: 15px 0;
}

.upcomAuc {
  max-width: 350px;
  min-width: 350px;
  width: 100%;
  position: relative;
  margin: auto;
  height: fit-content;
}

.gm-style-iw-d .upcomAuc {
  min-width: 285px;
}
.upcomAuc .image-gallery-svg {
  height: 40px;
}
.upcomAuc .cardDesc .currBid {
  font-size: 12px;
}

.upcomAuc .cardHeader img {
  width: 100%;
}

.upcomAuc .cardDesc .price {
  color: var(--primColor);
  font-size: 20px;
}
.upcomAuc .image-gallery-slide .image-gallery-image,
.upcomAuc .image-gallery-slide.grid-card-img {
  object-fit: cover !important;
  height: 210px;
  border-radius: 10px;
  max-height: 210px !important;
}
.slick-prev::before,
.slick-next::before {
  color: #000;
  font-size: 42px !important;
  opacity: 1;
}
.upcomAuc .features ul li {
  gap: 10px;
}
.upcomingAuctions .slick-list {
  padding: 0;
}
.upcomingAuctions .slick-list .slick-slide {
  /* padding: 0 30px; */
}
.upcomAuc .cardDesc {
  padding: 13px 0 5px;
  border-bottom: 0.4px solid #cecece;
}

.cardBottom {
  padding: 5px 0px 10px;
}

.search-wrapper .search-left .gm-style-iw-d .upcomAuc .cardDesc {
  padding: 13px 10px 5px;
}

.search-wrapper .search-left .gm-style-iw-d .cardBottom {
  padding: 5px 10px 10px;
}

.cardBottom .primButton button {
  height: 30px;
  border-radius: 30px;
}

.noOfBids {
  font-family: var(--fontFamilyTwo);
}

.upcomAuc .cardDesc h3 {
  font-size: 16px;
  margin-top: 0px;
  max-width: 245px;
  text-overflow: ellipsis;
  height: 40px;
  white-space: wrap;
  overflow: none;
  margin-bottom: 15px;
  font-family: var(--fontFamily);
}

.gm-style-iw-d .upcomAuc .cardDesc .features {
  margin-top: 10px;
}

.card-slider {
  /* width: 90%; */
  margin: 0 auto;
}

.upcomAuc .cardDesc .pricings {
  font-family: var(--fontFamilyTwo);
  display: flex;
  flex-direction: column;
  align-items: end;
}

.howWork .partTwo {
  grid-template-columns: 60% 40%;
}

.titleSec h4 {
  color: var(--primColor);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.sectionCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.sectionCont .right .title {
  color: var(--primColor);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.sectionCont .left .title {
  color: var(--secColor);
  font-size: 20px;
  font-weight: 600;
  text-align: right;
  margin: 0;
}

.sectionCont .left img {
  width: 100%;
  object-fit: contain;
}

.sectionCont .left.leftChange img {
  width: 100%;
  object-fit: contain;
  max-width: 400px;
}

.sectionCont .right .summery {
  margin: 0;
  padding-top: 12px;
  color: #9a9a9a;
  line-height: 24px;
  font-size: 16px;
}

.sectionCont .left,
.sectionCont .right {
  align-self: center;
}

.homePdCont section,
.upcomingAuctions {
  max-width: 1320px;
  margin: auto;
  padding: 0px 20px;
}

.sectionCont .left .summery {
  text-align: right;
  margin: 0;
  padding-top: 12px;
  line-height: 24px;
  color: #9a9a9a;
  font-size: 16px;
}

.stepsCont {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.stepsCont .stepWithBg {
  background-image: url("../../../assets/images/rectangle.png");
  width: 290px;
  height: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.stepsCont .stepWithBg::after {
  background-image: url("../../../assets/images/SignupCreate.png");
  width: 145px;
  height: 145px;
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  top: 19%;
  right: 0;
  bottom: auto;
  left: auto;
}

.stepsCont .stepWithBg:nth-child(2)::after {
  background-image: url("../../../assets/images/BrowsingOnline.png");
}

.stepsCont .stepWithBg:nth-child(3)::after {
  background-image: url("../../../assets/images/ForsaleRafiki.png");
}

.stepsCont .stepWithBg:nth-child(4)::after {
  background-image: url("../../../assets/images/winClose.png");
}

.stepsCont .stepWithBg .circleNum {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 2;
  background-color: #ffffff;
  border-radius: 50%;
  color: var(--primColor);
  margin-top: 19px;
  margin-left: 15px;
  font-weight: 600;
  font-size: 22px;
  box-shadow: 0px 2px 4px 1px #0000001f;
}

.stepsCont .stepWithBg .circleNum.clrChange {
  color: var(--secColor);
}

.stepsCont .stepWithBg p {
  margin-left: 15px;
  width: 100%;
  max-width: 125px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
}

.stepsCont .stepWithBg .stepTitle {
  font-size: 20px;
  font-weight: 700;
  margin-left: 15px;
  margin-top: 15px;
  max-width: 120px;
  min-height: 52px;
  line-height: 24px;
}

.processFlow {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 75px;
  justify-content: center;
}

.processFlow .flowCircle {
  text-align: center;
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 3px 15px 0px #00000012;
}

.processFlow .flowCircle h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  color: var(--primColor);
}

.processFlow .flowCircle h1.changeColor {
  color: var(--secColor);
}

.processFlow .flowCircle h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.InvCircle {
  background-image: url("../../../assets/images/Ellipse1.png");
  height: 350px;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
}

.InvCircle.InvCircleBgChange {
  background-image: url("../../../assets/images/Ellipse2.png");
}

.InvCircleBgChange h2 {
  color: var(--secColor);
}

.InvCircle .processFlow .flowCircle {
  position: absolute;
  z-index: 9;
  background: white;
}

.InvCircle .processFlow .flowCircle.flowCircle1 {
  transform: translate(210px, 22px);
}

.InvCircle .processFlow .flowCircle.flowCircle2 {
  transform: translate(340px, 86px);
}

.InvCircle .processFlow .flowCircle.flowCircle3 {
  transform: translate(484px, 122px);
}

.InvCircle .processFlow .flowCircle.flowCircle4 {
  transform: translate(630px, 122px);
}

.InvCircle .processFlow .flowCircle.flowCircle5 {
  transform: translate(770px, 86px);
  z-index: 8;
}

.InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle1 {
  transform: translate(267px, 55px);
}

.InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle2 {
  transform: translate(405px, 100px);
}

.InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle3 {
  transform: translate(550px, 122px);
}

.InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle4 {
  transform: translate(696px, 115px);
}

.InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle5 {
  transform: translate(832px, 60px);
  z-index: 8;
}

.InvCircle .processFlow .flowCircle.flowCircle6 {
  transform: translate(900px, 22px);
  z-index: 7;
}

.InvCircle .processFlow .flowCircle h1 {
  font-size: 18px;
  max-width: 115px;
  margin: auto;
  line-height: 1.5;
}

.InvCircle .processFlow {
  position: absolute;
  width: 100%;
}

.InvCircle .processFlow {
  grid-template-columns: auto auto auto auto auto auto;
}

.testimonial-carousel h2 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.testimonial-carousel h2 span {
  background: linear-gradient(91.87deg, var(--secColor) 0%, #f47025 99.19%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-content {
  padding: 20px;
  display: flex;
}

.testimonial-carousel .slick-slide.slick-active .image-wrapper {
  width: 80px;
  height: 80px;
}

.testimonial-carousel .slick-slide .image-wrapper {
  width: 52px;
  height: 52px;
}

.testimonial-carousel .slick-slide.slick-active .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid var(--secColor);
}

.testimonial-carousel .slick-slide.slick-active > div {
  text-align: center;
}

/* 
.testimonial-carousel .slick-slide.slick-active {
    width: 120px !important;
} */

.testimonial-carousel .slick-slide .testimonial-text,
.testimonial-carousel .slick-slide .image-wrapper h3,
.testimonial-carousel .slick-slide .testimonial span.material-icons {
  display: none;
}

.testimonial-carousel .slick-active.slick-center .testimonial-text,
.testimonial-carousel .slick-active.slick-center .image-wrapper h3,
.testimonial-carousel
  .slick-slide.slick-center
  .testimonial
  span.material-icons {
  display: block;
}

.testimonial-carousel .slick-slide .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid var(--primColor);
}

.testimonial-carousel .slick-active.slick-center .image-wrapper {
  width: 105px;
  height: 105px;
}

.testimonial-carousel .slick-active.slick-center .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid var(--primColor);
}

.testimonial-text {
  flex: 3;
  padding-left: 20px;
  position: relative;
}

.testimonial-text p {
  margin: 0;
  color: #9a9a9a;
  font-size: 16px;
}

.testimonial-text .quote {
  color: var(--primColor);
  position: absolute;
  top: -10px;
  left: -10px;
}

.testimonial-text .quote-end {
  color: var(--primColor);
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.image-wrapper h3 {
  color: var(--primColor);
  margin-top: 10px;
  font-size: 22px;
}

.slick-dots li button:before {
  color: var(--primColor);
}

.contactUsSec {
  margin-top: 30px;
  background-image: url("../../../assets/images/RectangleCont.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 620px;
  position: relative;
  display: flex;
}

.contactUsSec .contact-box {
  z-index: 1;
  position: relative;
  width: 100%;
  max-width: 820px;
  margin: auto;
  height: 480px;
  background-color: #ebf5ff;
  border: 2px solid var(--secColor);
}

.contactUsSec .contact-box h4 {
  text-align: center;
  padding-top: 30px;
  font-size: 22px;
  color: var(--secColor);
  font-weight: 600;
}

.contactUsSec .contact-box .MuiTabs-flexContainer {
  justify-content: center;
}

.contactUsSec .contact-box .MuiTabs-flexContainer .MuiTab-wrapper {
  text-transform: capitalize;
  font-weight: 600;
  color: #000000;
  font-size: 16px;
}

.contactUsSec .contact-box .MuiTab-textColorInherit {
  opacity: 1;
}

.contactUsSec .contact-box .PrivateTabIndicator-colorSecondary-11 {
  background-color: var(--primColor) !important;
}

.contactUsSec .contact-box .MuiTab-root {
  max-width: 80px;
  min-width: 80px;
}

.contactUsSec::after {
  content: "";
  background-color: #000000ad;
  position: absolute;
  top: 0;
  height: 620px;
  width: 100%;
}

.buyerCont label {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  margin: 0;
  padding: 0 !important;
  line-height: 24px !important;
  font-family: "Poppins" !important;
}

.buyerCont fieldset {
  border: none !important;
  height: 50px;
}

.primBtn button {
  max-width: 196px;
  width: 100%;
  height: 60px;
  margin: auto;
  background-color: var(--secColor);
  border-radius: 60px;
  border: 1px solid var(--secColor);
  display: flex;
}

.primBtn button span {
  font-size: 16px;
}

.buyerCont textarea {
  border: none;
}

@media (min-width: 768px) {
  .testimonial-carousel .slick-slide .testimonial {
    width: auto !important;
  }

  .testimonial-carousel .slick-slide.slick-center {
    width: 400px !important;
    margin: auto;
  }
}

@media (max-width: 1280px) {
  .InvCircle {
    max-width: 993px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle1 {
    transform: translate(107px, 11px);
  }

  .InvCircle .processFlow .flowCircle.flowCircle2 {
    transform: translate(220px, 86px);
  }

  .InvCircle .processFlow .flowCircle.flowCircle3 {
    transform: translate(360px, 123px);
  }

  .InvCircle .processFlow .flowCircle.flowCircle4 {
    transform: translate(510px, 122px);
  }

  .InvCircle .processFlow .flowCircle.flowCircle5 {
    transform: translate(640px, 70px);
  }

  .InvCircle .processFlow .flowCircle.flowCircle6 {
    transform: translate(751px, 7px);
  }

  .InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle1 {
    transform: translate(117px, 11px);
  }

  .InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle2 {
    transform: translate(240px, 86px);
  }

  .InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle3 {
    transform: translate(360px, 123px);
  }

  .InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle4 {
    transform: translate(510px, 86px);
  }

  .InvCircle.InvCircleBgChange .processFlow .flowCircle.flowCircle5 {
    transform: translate(640px, 11px);
  }
}

@media (max-width: 992px) {
  .testimonial-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .slick-prev {
    left: -22px;
  }
  .slick-next {
    right: 0;
  }

  .testimonial-carousel .slick-active.slick-center .image-wrapper {
    width: auto;
    height: auto;
    margin: auto;
  }

  .testimonial-carousel .slick-active.slick-center .image-wrapper img {
    width: 105px;
    height: 105px;
  }

  .BannerSection {
    padding: 40px 20px;
  }

  .BannerSection h3 {
    font-size: 25px;
    line-height: 1.25;
  }

  .BannerSection .Bannlft p {
    font-size: 14px;
    line-height: 1.45;
    padding: 15px 0;
  }

  .d-flex.homeBtn .primButton,
  .d-flex.homeBtn .secButton {
    width: 48%;
  }

  .BannerSection .homeBtn .primButton button,
  .BannerSection .homeBtn .secButton button {
    max-width: 100%;
    min-width: 100%;
    height: 45px;
    font-size: 14px;
    padding: 6px 10px;
  }

  .BannerSection .homeBtn .primButton button span,
  .BannerSection .homeBtn .secButton button span {
    font-size: 13px;
  }

  .contactUsSec::after {
    width: 100%;
    left: 0;
    height: 100%;
  }

  .contactUsSec {
    margin: 20px -15px;
    padding: 15px;
    height: auto;
  }

  .contactUsSec .contact-box {
    padding: 10px;
    height: auto;
  }

  .contactUsSec .contact-box .MuiTabs-flexContainer .MuiTab-wrapper {
    font-size: 14px;
  }

  .contactUsSec .contact-box .MuiTab-root {
    max-width: 68px;
    min-width: 68px;
  }

  .BannerSection .Bannrgt .bannerCard {
    max-width: 100%;
    min-height: auto;
  }

  .BannerSection .Bannrgt img {
    position: relative;
    right: 0;
    width: 100%;
    top: 0;
  }

  .BannerSection .Bannrgt .biddingComp {
    position: relative;
    bottom: 0;
    max-width: 100%;
    left: 0;
    right: auto;
    flex-wrap: wrap;
    padding: 15px;
  }

  .sectionCont .right .summery,
  .sectionCont .left .summery,
  .sectionCont .left .title {
    margin: 0 0 15px;
    line-height: 1.25;
    font-size: 14px;
  }

  .processFlow {
    grid-template-columns: 100px 100px 100px 100px;
    grid-gap: 20px;
    gap: 20px;
  }

  .sectionCont .left img,
  .sectionCont .right img,
  .sectionCont .left.leftChange img {
    max-width: 200px;
  }

  .stepsCont .stepWithBg,
  .BannerSection .Bannrgt,
  .BannerSection .Bannlft {
    width: 100% !important;
    max-width: 100%;
  }

  .processFlow .flowCircle {
    width: 100px;
    height: 100px;
  }

  .processFlow .flowCircle h1 {
    font-size: 20px;
  }

  .processFlow .flowCircle h4 {
    font-size: 14px;
    line-height: 24px;
  }

  .inv-con-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 55px;
    width: 100%;
    padding: 0 60px;
  }

  .titleSec h4 {
    font-size: 15px;
  }

  .InvCircle .processFlow {
    grid-template-columns: auto auto auto auto auto auto;
    position: initial;
    margin: 0 !important;
  }

  .InvCircle {
    background-size: 100%;
    margin: 30px auto;
  }

  .InvCircle {
    max-width: 345px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle1 {
    left: 34px;
    top: 3px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle2 {
    left: -32px;
    top: 145px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle3 {
    left: 34px;
    top: 260px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle4 {
    right: 34px;
    top: 260px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle5 {
    right: -32px;
    top: 145px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle6 {
    right: 34px;
    top: 3px;
  }

  .InvCircle {
    max-width: 280px;
    height: 280px;
  }

  .InvCircle .processFlow .flowCircle {
    transform: none !important;
    width: 75px;
    height: 75px;
  }

  .InvCircle .processFlow .flowCircle h1 {
    font-size: 12px;
  }

  .InvCircle .processFlow .flowCircle {
    transform: none !important;
  }

  .InvCircle .processFlow .flowCircle.flowCircle1 {
    left: 34px;
    top: 3px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle2 {
    left: -16px;
    top: 115px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle3 {
    left: 34px;
    top: 220px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle4 {
    right: 34px;
    top: 220px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle5 {
    right: -16px;
    top: 115px;
  }

  .InvCircle .processFlow .flowCircle.flowCircle6 {
    right: 34px;
    top: 3px;
  }

  .BannerSection .Bannrgt .bidBtn .primButton button,
  .BannerSection .Bannrgt .bidBtn {
    width: 100%;
    max-width: 100%;
    margin: 5px auto;
  }

  .sectionCont {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .sectionCont .left.leftChange img,
  .sectionCont.section-con-xs .right img,
  .sectionCont .left img {
    width: 100%;
    object-fit: contain;
    max-width: 280px;
    margin: 20px auto;
    padding: 0 !important;
  }

  .sectionCont .left .summery,
  .sectionCont .left .title {
    text-align: center;
  }

  .sectionCont.section-con-xs {
    display: flex;
    text-align: center;
    flex-direction: column-reverse;
  }

  .sectionCont.section-con-xs .right,
  .sectionCont .left {
    width: 100%;
  }

  .sectionCont .right .summery,
  .sectionCont .left .summery,
  .sectionCont .left .title {
    margin: 0 0 15px;
    line-height: 1.25;
    font-size: 14px;
  }

  .processFlow {
    grid-template-columns: 50% 50%;
  }

  .processFlow .flowCircle {
    margin: 0 auto;
  }
}

@media (max-width: 867px) {
  .cardBottom .primButton button {
    font-size: 10px;
  }

  .howWork .partOne .card h3,
  .howWork .partTwo .card h3 {
    font-size: 14px;
  }

  .howWork .partOne .card p,
  .howWork .partTwo .card p {
    font-size: 12px;
  }

  .howWork .partOne {
    grid-template-columns: 40% 58%;
  }

  .howWork .partTwo {
    grid-template-columns: 58% 40%;
  }
}

@media (max-width: 600px) {
  .BannerSection .Bannlft {
    padding: 50px 8px;
  }
  .searchBar .MuiOutlinedInput-root input {
    height: 40px !important;
    line-height: 4;
  }
  .searchBar .MuiOutlinedInput-root input::placeholder {
    font-size: 10px !important;
  }

  .searchBar .search-button {
    height: 40px;
    width: 40px;
  }
  /* .upcomingAuctions .slick-list .slick-slide {
    margin: 0 10px;
  } */
  /* .upcomingAuctions .slick-list
{
  padding: 0 10px !important;
} */
  .upcomAuc {
    min-width: fit-content;
    max-width: fit-content;
  }
  .homePdCont section,
  .upcomingAuctions {
    max-width: 1320px;
    margin: auto;
    padding: 0px 20px;
  }
  .BannerSection {
    padding: 20px 10px;
  }
  .card-slider {
    width: 100%;
  }
  .slick-prev {
    left: -35px;
  }
  .slick-next {
    right: -12px;
  }
  .changeContainer .slick-prev {
    left: -18px;
  }
  .changeContainer .slick-next {
    right: 0;
  }

  .upcomAuc {
    display: flex !important;
    width: 90vw !important;
    flex-direction: column !important;
  }

  /* .card-slider .slick-track {
    display: flex !important;
    flex-direction: column !important;
    width: 100vw !important;
  } */

  .cardDesc .features {
    padding: 10px 0px;
  }

  .cardBottom {
    padding: 10px 0px;
  }

  .gm-style-iw-d .upcomAuc .cardDesc .features {
    margin-top: 0;
  }

  .gm-style-iw-d .upcomAuc .cardDesc .features {
    padding: 5px 0px;
  }

  .upcomingAuctions .titleSec h2::after {
    top: 38px;
    width: 180px;
  }

  .howItWorks .titleSec h2::after {
    top: 36px !important;
    width: 250px !important;
  }

  .howWork .partOne,
  .howWork .partTwo {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }

  .howWork .partOne .card,
  .howWork .partTwo .card {
    padding: 5px 10px;
  }

  .footer .container .footerTwo {
    grid-template-columns: unset;
  }

  .titleSec h2 {
    font-size: 20px;
    font-weight: 400;
  }
  .howWork .card img {
    width: 110px;
  }

  .howWork .partOne .card p,
  .howWork .partTwo .card p {
    font-size: 14px;
  }

  .howWork .partOne .card h3,
  .howWork .partTwo .card h3 {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  .upcomAuc {
    min-width: 270px;
    max-width: 270px;
  }
}

@media (max-width: 360px) {
  .BannerSection .homeBtn {
    flex-wrap: wrap;
  }

  .d-flex.homeBtn .primButton,
  .d-flex.homeBtn .secButton {
    width: 100%;
  }

  .howItWorks .titleSec h2::after {
    top: 60px !important;
    width: 214px !important;
  }
}
