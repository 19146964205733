header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  /* position: absolute; */
  position: fixed;
}

.autologinMenu div a {
  color: #000 !important;
}

.hm-logo {
  max-height: 60px;
  width: 100%;
  min-width: 150px;
  object-fit: contain;
}

.header-container {
  width: 100%;
  position: sticky;
  background-color: #ffffff;
  z-index: 999;
}

.headerSpecial {
  max-width: 644px;
  width: 100% !important;
}
.headerSpecial .MuiInputBase-root {
  border-radius: 30px;
  padding-right: 0px;
}
.headerSpecial .MuiInputBase-root .MuiInputBase-input {
  height: 52px !important;
}
.headerSpecial .MuiInputBase-root .MuiInputAdornment-root {
  height: 46px;
  width: 55px;
  max-height: unset;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primColor);
  border-radius: 30px;
  color: #fff;
  margin-left: 0px;
  cursor: pointer;
}
.infoSectionHeader {
  background-color: var(--primColor);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 4px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.header-warpper-beforelg {
  width: 100%;
  margin: auto;
  /* max-width: 1280px; */
  /* position: sticky; */
  top: 0px;
  left: 0;
  right: 0;
  background-color: #fff;
  /* border-radius: 70px; */
  padding: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.afterLogin a {
  color: var(--primColor) !important;
}
.afterLogin .dropdown-menu a {
  color: #000 !important;
}
.header-warpper-beforelg .navbar-nav.header-fst {
  background-color: #ffffff;
  border-radius: 40px;
  width: 100%;
  /* max-width: 846px; */
  align-items: center;
  height: 60px;
}

.header-warpper-beforelg .navbar-nav.auth-btns-nav {
  width: auto;
}

.header-warpper-beforelg .navbar-nav.header-sec {
  background-color: #ffffff;
  border-radius: 40px;
  width: 100%;
  max-width: 193px;
  border: 1px solid #bebebe;
  height: 60px;
  justify-content: center;
}

.header-warpper-beforelg .navbar-nav.header-sec li a,
.header-warpper-beforelg .navbar-nav.header-fst li a {
  color: #fff !important;
  font-size: 16px;
  font-family: var(--fontFamily);
  text-decoration: none;
}
.loginReg {
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  padding: 10px 20px;
}
.marketPlaceButton {
  font-size: 16px !important;
  text-transform: capitalize !important;
  margin-right: 30px !important;
}
.header-warpper-beforelg .navbar-nav.header-fst li {
  text-decoration: none !important;
  cursor: pointer;
  padding: 0px 3px;
}
.afterLogin {
  padding: 10px 20px !important;
  border-radius: 6px;
  background-color: var(--primColor);
}
.marketDropMenu,
.helpDropMenu {
  padding: 5px 0px;
  min-width: 130px;
  width: 100%;
  margin-bottom: 0px;
}
.marketDropMenu .MuiListItem-button .PmCategoryTitle,
.helpDropMenu .MuiListItem-button .PmCategoryTitle {
  font-size: 16px;
}
.helpDropMenu a,
.marketDropMenu a {
  color: #000;
}
.helpDropMenu a:hover,
.marketDropMenu a:hover {
  color: var(--primColor);
  text-decoration: none;
}
.nav-item.dropdown-resp {
  line-height: 0.2;
}
.nav-item.dropdown-resp span.material-icons {
  font-size: 16px;
}
.nav-item.dropdown-resp .dropdown-menu a {
  color: #000000 !important;
  margin-bottom: 5px;
  display: inline-block;
}
.header-warpper-beforelg .navbar-nav.header-sec li {
  text-decoration: none;
}

.header-warpper-beforelg .navbar-nav.header-sec li:hover {
  color: var(--primColor) !important;
}

/* .header-warpper-beforelg .navbar-nav li a{
  color: var(--primColor);
  font-size: 16px;
  text-decoration: none;
} */

.header-warpper-beforelg .navbar-collapse.top-menu {
  gap: 20px;
  grid-gap: inherit;
}

header .navbar {
  padding: 0 15px;
}
header .navbar .navbar-header {
  width: 160px;
}
header .navbar-expand-lg .navbar-nav > li {
  padding: 10px;
}

header .navbar-expand-lg .navbar-nav > li a,
.mobile-menu .dropdown a {
  text-transform: none;
  color: white;
  font-size: 12px;
  font-family: "Avenir";
  position: relative;
  cursor: pointer;
}

header .navbar-expand-lg .navbar-nav > li a {
  text-decoration: none;
}

header .navbar-expand-lg .navbar-nav > li a:after,
.mobile-menu .notify:after {
  border: none;
  content: "";
  width: 100%;
  height: 2px;
  bottom: -20px;
  left: 0;
  background: transparent;
  position: absolute;
  display: none;
}

header .navbar-expand-lg .navbar-nav > li:hover a:after,
header .navbar-expand-lg .navbar-nav > li a.active:after,
.mobile-menu .notify:hover:after,
.mobile-menu .notify.active:after {
  background: var(--primColor);
}

header .navbar-expand-lg .navbar-nav li:hover a.btn-rg:after {
  content: none;
}

header .navbar-expand-lg .navbar-nav li .btn-rg,
.mobile-menu .btn-rg {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid white;
  color: #fff;
  padding: 0;
  border-radius: 3px;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 40px;
  width: 113px;
  line-height: 1em;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir-light";
}

.inner-header {
  background-color: white;
}

header.inner-header .navbar-expand-lg .navbar-nav li {
  cursor: pointer;
}

header.inner-header .navbar-expand-lg .navbar-nav li a,
.mobile-menu .notify,
.mobile-menu .dropdown-toggle,
.mobile-menu .dropdown a {
  color: #fff;
  font-size: 16px;
  font-family: var(--fontFamily);
  text-decoration: none;
}

header.inner-header .navbar-expand-lg .navbar-nav li:hover a,
header .navbar-expand-lg .navbar-nav li a.active,
.mobile-menu .notify:hover,
.mobile-menu .dropdown-toggle:hover,
.mobile-menu .notify.active {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.notify {
  position: relative;
}

.blue-circle {
  width: 5px;
  height: 5px;
  position: absolute;
  background: var(--primColor);
  border-radius: 5px;
  right: 1px;
  top: 0px;
}

.fs-16 {
  font-size: 16px;
}

.user-circle {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f5f7;
  border-radius: 50%;
  color: var(--primColor);
  text-align: center;
  font-size: 10px;
  padding: 1px;
  margin-right: 10px;
}
.user-circle i {
  font-size: 16px;
}

header .dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  left: inherit;
  min-width: 135px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu a,
.mobile-menu .dropdown-menu a {
  display: block;
  padding: 8px 8px 8px 12px;
  color: #000 !important;
  border-left: 3px solid transparent;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu .buyer-verification-link a,
.mobile-menu .dropdown-menu .buyer-verification-link a {
  display: flex;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu a:after,
.mobile-menu .dropdown-menu a:after {
  display: none;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu a:hover,
header .navbar-expand-lg .navbar-nav .dropdown-menu a:focus,
header .navbar-expand-lg .navbar-nav .dropdown-menu a.active,
.mobile-menu.dropdown-menu a:hover,
.mobile-menu .dropdown-menu a:focus,
.mobile-menu .dropdown-menu a.active {
  background: #f2f5f7;
  color: black;
  border-left: 3px solid var(--primColor);
}

.dropdown-menu .cwhite-box {
  margin: 0;
  box-shadow: none;
  background: transparent;
  padding: 0 12px;
}

.dropdown-menu .btn-primary {
  display: none !important;
}

.dbw-box .db-menu {
  padding-bottom: 0;
}

.navbar-nav .MuiBadge-colorPrimary {
  background: var(--primColor);
}

.navbar-nav .MuiBadge-anchorOriginTopRightRectangle {
  top: -5px;
}

.doorsey-no a::after {
  display: none;
}

.doorsey-no a span {
  text-decoration: underline;
}

.nav-mob-btns {
  margin-top: 5rem;
}

.nav-mob-btns .phone-no a {
  color: black;
}

.nav-mob-btns .submit-home a {
  color: #fafafa;
}

.nav-mob-btns .submit-home {
  background-color: #141414;
  width: 100%;
  padding: 10px;
  border: none;
}

.nav-mob-btns .phone-no {
  background-color: #eaeaea;
  border: none;
  padding: 10px;
  width: 100%;
}

.nav-mob-btns {
  padding: 0;
  list-style: none;
}

@media (min-width: 992px) {
  .wd-240 {
    min-width: 240px;
  }
}

@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1240px) {
  header .navbar {
    padding: 5px 30px;
  }

  header .navbar-expand-lg .navbar-nav li .btn-rg {
    margin-left: 0;
    width: 95px;
  }

  header .navbar-expand-lg .navbar-nav > li {
    padding: 10px 4px;
  }

  header .navbar-expand-lg .navbar-nav > li a,
  .mobile-menu .dropdown a {
    font-size: 11px;
  }

  .main-container {
    padding: 70px 0 0;
    min-height: calc(100vh - 70px);
  }
}

@media (max-width: 992px) {
  .header-warpper-beforelg {
    padding: 10px 15px;
  }
  .lo-link-xs {
    padding: 12px;
    background: transparent;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #505d68;
    display: block;
    cursor: pointer;
    border-left: 3px solid transparent;
    margin-bottom: 2px;
    font-family: "Avenir";
    font-weight: 600;
  }
  .mobileViewTest {
    display: none;
  }

  .mobile-menu {
    display: flex;
    position: absolute;
    right: 40px;
    top: 18px;
  }

  header .container-fluid {
    padding: 0;
  }

  header .navbar {
    padding: 5px 0;
    position: relative;
  }

  .mblNwHdr {
    width: 100%;
  }

  .mblNwHdr .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  header .navbar .navbar-header {
    width: 100%;
    position: relative;
  }

  .navbar-toggler,
  .navbar-toggler-search {
    position: absolute;
    left: 0;
    height: 20px;
    top: 2px;
    bottom: 0;
    margin: auto;
    padding: 0;
  }

  .navbar-toggler-search {
    left: inherit;
    right: 65px;
    font-size: 1.25rem;
    top: 0px;
    height: 24px;
    color: #000;
  }

  .header-warpper-beforelg .navbar-nav.header-fst {
    height: auto;
    align-items: flex-start !important;
    padding: 0 !important;
  }

  .header-warpper-beforelg .navbar-nav.auth-btns-nav {
    width: auto;
    position: absolute;
    right: 0;
    height: 36px;
    padding: 0 !important;
  }

  .header-warpper-beforelg .navbar-nav.auth-btns-nav .hb-text {
    display: none;
  }

  .header-warpper-beforelg .navbar-nav.auth-btns-nav li {
    padding: 5px !important;
  }

  .header-warpper-beforelg .navbar-nav.auth-btns-nav li .user-circle {
    margin-right: 0;
  }

  .navbar-brand {
    display: block;
    width: 150px;
    margin: 0 auto;
    padding: 0;
  }

  .navbar-header .dropdown.dd-mobile {
    position: absolute;
    right: 60px;
    top: 0;
    margin: auto;
    bottom: 0;
    height: 20px;
  }

  .dropdown.dd-mobile .dropdown-toggle,
  .dd-mobile .user-circle {
    color: black;
    font-size: 16px;
  }

  .dropdown.dd-mobile .dropdown-menu li a {
    color: #4f4f4f;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins";
  }

  .navbar-collapse {
    position: absolute;
    width: 100%;
    background: #fff;
    left: 0;
    top: 75px;
    padding: 0 15px;
    /* min-height: calc(100vh - 55px); */
    overflow: auto;
    /* max-height: calc(100vh - 55px); */
    padding-bottom: 20px;
    width: 112%;
    left: -6%;
    box-shadow:
      0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .header-wrap .collapse.navbar-collapse:not(.show) {
    display: none !important;
  }

  header .navbar-expand-lg .navbar-nav li .btn-rg {
    margin-left: 0;
  }

  header .navbar-expand-lg .navbar-nav > li a {
    color: #9faebb;
  }

  header .navbar-expand-lg .navbar-nav li .btn-rg {
    border: 1px solid var(--primColor) !important;
    color: var(--primColor) !important;
  }

  header .navbar-expand-lg .navbar-nav > li a:after {
    bottom: -8px;
  }

  .mobile-menu .notify {
    margin: 4px 8px;
  }

  .mobile-menu .dropdown {
    margin: 2px 8px;
  }

  .home-header .mobile-menu .notify,
  .home-header .mobile-menu .dropdown-toggle {
    color: #fff;
  }

  .mobile-menu .btn-rg {
    height: 30px;
    padding: 8px 10px;
    width: 85px;
    margin: 0px 10px;
  }

  .mobile-menu .notify:after {
    bottom: -14px;
  }

  header {
    position: fixed;
    padding: 5px 0;
    z-index: 1300;
  }
}
