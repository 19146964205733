.dashContentWrapper {
  padding: 46px 28px;
  padding-bottom: 40px;
  background-color: #fff;
  font-family: "Poppins";
}

.dashContentWrapper .transParentBtn button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: transparent;
  color: #134663;
  border: none;
  padding-left: 0;
}

.tabCustWidth button {
  width: 100%;
  margin: 0;
  background: white;
  font-size: 16px;
  opacity: 1;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}

.tabCustWidth .MuiTabs-scroller.MuiTabs-fixed {
  overflow: auto !important;
}

.tabCustWidth button.Mui-selected {
  border-bottom: 4px solid var(--primColor);
  background-color: transparent;
  color: #000;
  font-weight: 600;
  text-transform: capitalize;
}

.tabCustWidth .MuiPaper-root.MuiAppBar-root {
  background-color: transparent;
  box-shadow: none;
  margin-top: 0;
}

.dashContentWrapper.search-card {
  margin-bottom: 16px;
  display: flex;
  padding: 27px 26px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 14px 0px #0000001a;
  border-radius: 12px;
}
.historyPage .dashContentWrapper.search-card {
  border-radius: 12px 12px 0 12px;
}
.dashContentWrapper.search-card.saved-SearchCard {
  padding: 20px 22px;
}

.search-card-details .biddingButtons button {
  height: 40px;
  background-color: #134663;
  max-width: fit-content;
  text-transform: uppercase;
  border: 1px solid #134663;
  /* margin-top: 15px; */
}

.search-card-details .biddingButtons.custBid button {
  margin: 0;
}

.search-card-details .biddingButtons button:hover {
  background-color: transparent;
  color: #134663;
}

.search-card-details .biddingButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.dashContentWrapper.search-card .imgCont {
  max-height: 200px;
  width: 100%;
  border-radius: 12px 0 0 12px;
  position: relative;
  max-width: 192px;
  min-height: 168px;
  height: auto;
}

.dashContentWrapper.search-card p.paraTite {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dashContentWrapper.search-card .imgCont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 12px 0 0 12px;
}

.tmrCont {
  position: absolute;
  content: "";
  background-color: #00000060;
  bottom: 0;
  height: 35px;
  width: 100%;
  border-bottom-left-radius: 12px;
}

.imgCont .favIconCont {
  position: absolute;
  top: 8px;
  right: 8px;
  left: auto;
  bottom: auto;
  height: 30px;
  width: 30px;
  /* background: rgba(255, 255, 255, 0.8); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dashContentWrapper.search-card .favIconCont img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.imgCont .favIconCont span.material-icons {
  color: #f42525;
}

.tmrCont .tmr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 100%;
  color: #ffffff;
  border-bottom-left-radius: 12px;
}

.search-card-header {
  display: flex;
  /* align-items: center; */
  max-width: 400px;
  width: 100%;
}

.search-card-header.ss-400 {
  max-width: 400px;
}

.search-card-details .mw-120 {
  min-width: 120px;
}

.viewsContent {
  display: flex;
  gap: 10px;
  margin-top: 25px;
  flex-wrap: wrap;
}

.viewsContent > p {
  display: flex;
  gap: 3px;
  align-items: baseline;
}

.viewsContent > p .contTit {
  font-weight: 400;
  font-size: 14px;
}

.viewsContent > p .contCount {
  font-weight: 600;
  font-size: 14px;
}

.search-card-actions {
  position: relative;
}

.search-card-actions .shareProd {
  position: absolute;
  top: 10px;
  right: 5px;
  left: auto;
  bottom: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  gap: 3px;
  font-size: 14px;
}
.search-card-actions .shareProd span {
  font-size: 18px;
}

.pndCard .search-card-header {
  max-width: 400px;
}

.search-card-header h2 {
  margin: 0;
  margin-right: 10px;
  line-height: 27px;
  font-weight: 600;
  text-align: left;
  font-size: 18px;
  color: var(--primColor);
}
.saved-SearchCard .search-card-header h2 {
  color: #000;
}
.saved-SearchCard .search-card-details {
  border-top: 0px;
  display: none;
}
.search-card-actions p.currbid {
  margin: 0;
  font-size: 12px;
  line-height: 21px;
  color: #000;
  float: right;
}

.search-card.pndCard {
  margin-bottom: 80px;
}

.search-card-actions h5.bidValue {
  color: var(--primColor);
  font-size: 22px;
  margin: 0;
  font-weight: 700;
  float: right;
}

.search-card-actions .biddingValue {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: var(--fontFamilyTwo);
}

.search-card-actions.changeAli {
  display: flex;
  gap: 5px;
  align-items: end;
  justify-content: space-between;
}
.share-card .headTil {
  font-family: var(--fontFamily);
}
.compStepper {
  position: absolute;
  bottom: -60px;
  width: 100%;
  left: 0;
  background: white;
  /* box-shadow: 0px 6px 5px #8888881c inset; */
  box-shadow: 0px 15px 20px -15px #88888838 inset;
  height: 60px;
  border-bottom: 1px solid #80808012;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-around;
}

.search-card-details p.dateColor {
  color: #134663;
  font-size: 14px;
  margin: 0 0 5px;
}

.search-card-details p.dateColor.ContrRel {
  color: #000000;
  font-size: 12px;
  margin: 0 0 5px;
}

.search-card-actions p.dateColor {
  color: #134663;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 5px;
}

.biddingButtons .timr {
  display: flex;
  gap: 5px;
  background-color: #20aa68;
  color: #ffffff;
  height: 25px;
  width: 115px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-self: flex-end;
  margin-bottom: 8px;
  font-size: 14px;
}

.biddingButtons img {
  height: 14px;
  width: 14px;
  margin: auto 0;
  object-fit: contain;
}

.biddingButtons span {
  margin: auto 0;
}

.search-card-header > div .compCont {
  color: #20aa68;
  align-self: flex-end;
  margin-top: 10px;
}

.compStepper > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.compStepper > div h4 {
  font-size: 12px;
  font-weight: 400;
  color: #00000060;
  padding-top: 7px;
}

.compStepper > div.active h4 {
  color: #000000;
}

.compStepper > div span {
  font-size: 16px;
  color: #03519b60;
}

.compStepper > div.active span {
  color: #03519b;
}

.saved-badge {
  background-color: #20aa6820;
  color: #20aa68;
  border: 1px solid #20aa68;
  border-radius: 15px;
  padding: 4px 20px;
  width: fit-content;
  font-size: 12px;
}

.saved-badge.outbid {
  background-color: #ffcc0020;
  border: 1px solid #ffcc00;
  color: #ffcc00;
}

.saved-badge.lostbid {
  background-color: #fb000020;
  border: 1px solid #fb0000;
  color: #fb0000;
}

/* .search-card-details {
  flex-grow: 1;
} */

.search-card-details hr {
  width: 27px;
  rotate: 90deg;
  margin-bottom: 0;
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
}

.search-card-details p {
  margin: 4px 0;
  font-size: 14px;
}

.search-card-details p {
  line-height: 24px !important;
}

.search-card-details .availImg {
  display: flex;
  padding-left: 18px;
  gap: 34px;
}
.search-card-details .availImg .listing-type::marker {
  color: #d9d9d9;
}
.search-card-details .availImg .listing-type img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.search-card-details .availImg > div {
  display: flex;
  gap: 12px;
  align-items: center;
}

.search-card-details .availImg > div img {
  object-fit: contain;
  width: 18px;
  height: 18px;
}

.search-card-details .listing-type {
  font-weight: 500;
}

.search-card-actions {
  display: flex;
  align-items: center;
}

.search-card-actions button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #000;
  height: 42px;
}
.pv-white-box .stepperbefore .MuiStep-alternativeLabel:nth-child(1) {
  padding-right: 8px;
}
.search-card-actions button span.material-icons {
  font-size: 16px;
}

.modalTitleCont h4.title {
  font-size: 18px;
  color: var(--primColor);
}

.dashContentWrapper .cc-options {
  max-width: 100%;
  padding: 0;
}

.dashContentWrapper .cc-options ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashContentWrapper .cc-options ul li {
  font-family: "Poppins" !important;
}

.dashContentWrapper .cc-options ul li.actionBtn {
  display: flex;
  gap: 15px;
  align-items: center;
}

.dashContentWrapper .cc-options ul li.progressComp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dashContentWrapper .cc-options ul li.progressComp .MuiLinearProgress-root {
  width: 5px;
  height: 6px;
  min-width: 80px;
  border-radius: 15px;
}

.dashContentWrapper
  .cc-options
  ul
  li.progressComp
  .MuiLinearProgress-root
  > div {
  background-color: var(--primColor);
}

.dashContentWrapper .cc-options ul li.actionBtn span {
  font-size: 16px;
  color: #134663;
}

.dashContentWrapper .cc-options ul li.actionBtn > div {
  display: flex;
  gap: 5px;
  cursor: pointer;
  color: #134663;
  font-family: "Poppins" !important;
}

.dashContentWrapper .tit {
  font-size: 20px;
  color: #134663;
  font-weight: 500;
  margin-bottom: 15px;
}

.dashContentWrapper p {
  font-size: 15px;
  color: #000000;
  line-height: 22px;
}

.dashContentWrapper h6 {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}

.modalViewChangeProfile .customSelect label {
  margin-bottom: 2px;
}

.dashContentWrapper .MuiPaper-root {
  box-shadow: unset;
}
.stepOneTemp .tit.modTitle {
  font-size: 14px;
}
.stepOneTemp .tit.modTitle .status {
  color: #9faebb;
}

.dashContentWrapper .MuiTabs-scroller .MuiTabs-flexContainer {
  gap: 15px;
}
.tabCustWidth .MuiTabs-flexContainer {
  gap: 5px;
}

.dashContentWrapper
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root {
  border: 1px solid #c1c1c1;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.pv-white-box .MuiStep-alternativeLabel:nth-child(1) {
  padding-right: 22px;
}
.MuiStepConnector-alternativeLabel.MuiStepConnector-completed
  .MuiStepConnector-lineHorizontal.MuiStepConnector-line {
  border-color: var(--primColor);
}
.MuiStepConnector-alternativeLabel.MuiStepConnector-active
  .MuiStepConnector-lineHorizontal.MuiStepConnector-line {
  border-color: var(--primColor);
}
.dashContentWrapper
  .MuiTabs-scroller
  .MuiTabs-flexContainer
  .MuiButtonBase-root.Mui-selected {
  border: 1px solid #f47025;
}

.muistepperController .finalStage {
  max-width: 450px;
  margin: auto;
}

.muistepperController .finalStage span {
  font-size: 50px;
  color: green;
  margin-bottom: 25px;
  display: block;
  text-align: center;
}

.muistepperController .finalStage .tit {
  color: #03519b;
  font-size: 18px;
  text-align: center;
}

.muistepperController .finalStage .para {
  color: #656565;
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
}

.muistepperController {
  padding: 25px 0;
}

.stepOneTemp .tit {
  font-size: 26px;
  font-weight: 600;
  color: #134663;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .tabCustWidth button {
    max-width: 200px;
  }
}
@media (max-width: 1024px) {
  .tabCustWidth button {
    margin: 0 10px;
    max-width: fit-content;
  }

  .dashContentWrapper .MuiStep-alternativeLabel:nth-child(1) {
    flex-grow: 0;
    padding: 0 8px;
    z-index: 110;
    min-width: 33%;
  }
}

@media (max-width: 767px) {
  .dashoard-right .MuiStepper-horizontal {
    overflow: auto;
  }

  .dashContentWrapper .cc-options ul {
    flex-direction: column;
  }
  .dashContentWrapper.search-card {
    padding: 20px;
    flex-wrap: wrap;
    gap: 10px;
  }
  .dashContentWrapper {
    padding: 20px;
  }
  .dashContentWrapper.search-card .imgCont + .d-flex {
    flex-direction: column;
  }

  .search-card-actions.changeAli {
    flex-direction: row;
  }

  .dashContentWrapper.search-card .imgCont {
    height: auto;
  }
}

@media (max-width: 479px) {
  .dashContentWrapper.search-card .d-flex {
    flex-direction: column;
  }
  .dashContentWrapper.search-card .imgCont img {
    border-radius: 12px;
  }
  .search-card-actions .biddingValue {
    align-items: center;
    margin-top: 15px;
  }
  .search-card-details .listing-type {
    font-size: 12px;
  }
  .dashContentWrapper.search-card .imgCont {
    max-height: 330px;
  }
  .dashContentWrapper.search-card .imgCont {
    max-width: 100%;
  }
  .search-card-details .availImg .listing-type img {
    width: 15px;
    height: 15px;
  }
}
