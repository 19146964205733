.search-wrapper {
  display: flex;
  height: auto;
  padding-top: 0;
  /* margin-bottom: -60px; */
  background: #fff;
}

.search-wrapper .search-left {
  flex: 1;
  position: sticky;
  top: 0;
  height: calc(100vh - 160px);
}
.PropertyfilterButtonGroup #menu-list-grow {
  max-height: 400px;
  height: 100%;
  overflow: auto !important;
}
.search-wrapper .search-right {
  width: 750px;
  padding: 0 8px;
  overflow: auto;
}
.searchBarHere .MuiFormControl-root {
  width: 100%;
  max-width: 500px;
}
.searchBarHere .MuiFormControl-root .MuiInputBase-root {
  border-radius: 6px;
  margin-top: 5px;
}
.searchBarHere .MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  color: var(--primColor);
}
.search-bottom {
  padding: 5px 15px;
  font-family: "Avenir-Medium";
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #000;
  position: absolute;
  bottom: 12px;
  left: 12px;
  background: white;
  z-index: 99;
  border-radius: 4px;
  width: max-content;
  height: 30px;
}

.sr-top {
  background: #f8fafb;
  padding: 14px 10px;
}

.sr-top-head {
  font-size: 24px;
  color: #000;
  line-height: 32.78px;
}

.no-results {
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  margin-right: 20px;
  white-space: pre;
}

.fs-link-btn {
  background: #f2f5f7;
  border: 1px solid #f2f5f7;
  width: auto;
  height: 40px;
  padding: 10px;
  font-family: "Avenir-Medium";
  font-size: 12px;
  text-align: center;
  color: var(--primColor);
  display: block;
  min-width: 75px;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: pre;
}

.fs-link-btn:focus,
.fs-link-btn:hover {
  background: #fff;
  border: 1px solid var(--primColor);
  text-decoration: none;
}

.search-filters-wrap {
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
}

.search-filters-wrap > div {
  margin: 8px 0;
  width: calc(25% - 4.5px);
}

.search-filters-wrap select.form-control {
  font-family: Avenir-Medium;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #9faebb;
  height: 40px;
  padding: 10px 5px;
  min-width: 92px;
}

.sg-wrapper {
  display: flex;
  padding: 0 8px;
  overflow: auto;
  row-gap: 15px;
  gap: 18px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.image-gallery-slides {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  max-height: calc(100vh - 130px);
  height: 100%;
}
.sg-wrapper > div {
  width: calc(50% - 9px);
  min-width: calc(50% - 9px);
  margin: 0;
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #9faebb;
}

.mapboxgl-map {
  max-height: calc(100% - 50px);
}

.searchFilter {
  padding: 30px 45px;
  min-width: 20vw;
}

.searchFilter h5 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 25px;
}

.searchFilter .MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
}

.searchFilter h2 {
  font-weight: 800;
}

.searchFilter .MuiFormControlLabel-root {
  margin: 0;
}

.search-filters-wrap .btn {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}
.dropHeadings {
  font-weight: 800;
  color: var(--primColor);
  font-size: 17px;
}
.ss-wrap {
  position: relative;
}

.ss-wrap > span {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 800;
}
button.selectedBtn {
  background-color: var(--primColor) !important;
  color: white;
}

.ss-wrap .form-control {
  color: var(--primColor) !important;
  font-weight: 800;
}

.search-filters-wrap .customInput .MuiTextField-root {
  margin-bottom: 0;
}

.search-filters-wrap .customInput .MuiInputBase-root {
  height: 40px;
}

.search-filters-wrap .customInput .MuiTypography-colorTextSecondary {
  z-index: 1;
}

.search-filters-wrap .customInput .MuiOutlinedInput-root input {
  padding-left: 5px !important;
  height: 40px !important;
}

.search-filters-wrap .MuiOutlinedInput-adornedStart {
  padding-left: 10px;
}

.clsContainer {
  display: none;
}

.sfInner .MuiTypography-colorTextSecondary {
  z-index: 1;
  font-size: 13px;
}

.sfInner .MuiOutlinedInput-adornedEnd {
  padding-right: 5px;
}

.filterBadge {
  background: var(--primColor);
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
}

.mobile-sg-wrapper {
  margin-top: 10px;
}

.PropertyfilterButtonGroup .filterMenuTit {
  font-weight: 700;
  padding: 16px 10px;
  background-color: #f6f6fa;
  color: #596b82;
  margin: 0;
  font-size: 14px;
}

.PropertyfilterButtonGroup {
  gap: 15px;
}

.priceRangeMenuBody .priceGridBox,
.PropertyfilterButton .priceRangeMenuBody .priceGridBox {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

.priceRangeMenuBody,
.PropertyfilterButton .priceRangeMenuBody {
  padding: 12px;
  background-color: #fff;
}
.priceRangeMenuBody {
  max-height: 281px;
  overflow-y: auto;
  padding: 12px;
}
.priceRangeMenuBody .RadioBox .MuiFormGroup-root,
.PropertyfilterButton .priceRangeMenuBody .RadioBox .MuiFormGroup-root {
  flex-direction: column;
}

.PropertyfilterButton .filterBtn {
  border: 1px solid #b1b1b1;
  text-transform: capitalize;
  min-width: 150px;
  border-radius: 6px;
}

.filterSectionBtns.primButton .MuiButton-root {
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  border-radius: 4px;
}

.priceRangeMenuBody .etOtIZ .MuiButton-root:nth-last-child(1),
.PropertyfilterButton
  .priceRangeMenuBody
  .etOtIZ
  .MuiButton-root:nth-last-child(1) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.etOtIZ button.MuiButton-root {
  border: 1px solid #efefef;
  min-width: 70px;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}
.selectAllChekbox {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.selectAllChekbox > div:hover {
  text-decoration: underline;
}

.selectAllChekbox span {
  font-size: 20px;
  text-decoration: none;
}
.etOtIZ {
  border: 1px solid #efefef;
}
.etOtIZ button.MuiButton-root:first-child {
  border: 0;
}

.PropertyfilterButton .priceRangeMenuBody .etOtIZ .MuiButton-root:nth-child(1) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.PropertyfilterButton .priceRangeMenuBody .etOtIZ .MuiButton-root {
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 10px 14px;
  margin: 0px;
  width: auto;
  position: relative;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  height: auto;
  margin-left: -1px;
  flex: 1 1 0%;
}

.PropertyfilterButton .priceRangeMenuBody .etOtIZ {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

.sg-wrapper .image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.homeTypeMultiCheck .MuiFormControlLabel-root {
  margin: 0;
}

.homeTypeMultiCheck.MuiListItem-gutters {
  padding: 0;
}

.homeTypeMultiCheck .MuiCheckbox-root {
  padding: 0;
}

.MuiListItem-button:hover {
  background-color: transparent;
}
.MuiListItem-button > div {
  width: 100%;
}
.MuiListItem-button > div label {
  width: 100%;
}
.MuiListItem-button > div label span.MuiListItems-gutters {
  width: 100%;
}
.MuiListItem-button > div label span.MuiTypography-root {
  width: 100%;
}

.search-wrapper .search-left .gm-style .gm-style-iw-c {
  padding: 0;
  min-height: 360px;
  min-width: 285px !important;
}

.search-wrapper .search-left .gm-style-iw-d .grid-card {
  margin-bottom: 0 !important;
}

.search-wrapper .image-gallery-slide .image-gallery-image {
  object-fit: cover;
}

.search-wrapper .search-left .gm-style-iw-d {
  overflow: hidden !important;
  padding: 0 !important;
  max-width: 285px;
  min-height: 360px;
}

.search-wrapper .search-left .gm-style-iw-chr {
  display: none;
}

.search-left .image-gallery-left-nav .image-gallery-svg,
.search-left .image-gallery-right-nav .image-gallery-svg,
.sg-wrapper .image-gallery-left-nav .image-gallery-svg,
.sg-wrapper .image-gallery-right-nav .image-gallery-svg {
  font-size: 10px;
  width: 20px !important;
}

.propertyAnglesCalulation .filterLabel {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #595959;
}

.propertyAnglesCalulation .propertyFieldsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.fixedFilterBottom .secButton,
.fixedFilterBottom .primButton {
  margin: 0 !important;
}

.fixedFilterBottom {
  margin-top: 15px;
  position: sticky;
  bottom: 0;
  top: auto;
  background-color: #fff;
  width: 100%;
  padding: 0px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  gap: 10px;
}

/* .innerScrollerBody {
  max-height: 281px;
  overflow-y: auto;
  padding: 12px;
} */

.listingtabTypes .MuiTab-textColorPrimary.Mui-selected {
  color: #3f51b5;
  font-weight: 700;
}

.listingtabTypes .MuiTab-textColorPrimary {
  border: 1px solid #efefef;
  font-size: 14px;
  text-transform: capitalize;
}

.listingtabTypes .MuiPaper-root {
  box-shadow: unset;
  background-color: #fff;
}

.listingtabTypes .moreFilterTabContent {
  padding: 10px 0;
}

.listingTabContent .MuiFormControlLabel-root {
  margin-left: 0;
}

.listingTabContent .MuiCheckbox-root .MuiSvgIcon-root {
  font-size: 22px;
}

.listingTabContent .MuiCheckbox-root {
  padding: 0px;
}

.drawPropertyBtns button:hover {
  background-color: #fff;
}

.drawPropertyBtns button {
  background-color: #fff;
  padding: 6px 12px;
  height: 35px;
  border-radius: 4px;
  text-transform: capitalize;
}

.drawPropertyBtns .drawPara {
  font-size: 14px;
  margin: 0;
  color: #fff;
  font-weight: 500;
}

.drawSection button {
  background-color: #fff;
}

.drawSection {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 10px;
  right: 0;
  left: auto;
  text-align: right;
}

.drawPropertyBtns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  top: 0;
  z-index: 99;
  background: #00000069;
  width: 100%;
  padding: 10px;
}

.saved-menu .MuiMenu-list {
  width: 400px;
}

.saved-menu .search-saved,
.saved-menu .search-saved:hover {
  padding: 12px 16px;
  background: rgb(216, 244, 222);
  color: rgb(18, 138, 41);
  justify-content: space-between;
  margin-top: -8px;
  margin-bottom: 15px;
  font-family: "Poppins";
}

.ss-copy-link {
  font-family: "Poppins";
  color: #03519b;
  font-weight: 600;
}

@media (min-width: 768px) {
  .map-sort {
    display: none;
  }

  .filterPhoneDrawer {
    font-size: 20px;
  }
  .PropertyfilterButtonGroup #menu-list-grow {
    max-width: auto;
  }
}

@media (max-width: 991px) {
  .search-wrapper {
    flex-wrap: wrap;
    overflow: inherit;
    height: auto;
    background: #f2f5f7;
    position: relative;
    margin: 0;
    min-height: calc(100vh - 225px);
  }

  .srt-wrapper {
    width: 100%;
  }

  .search-wrapper .search-right,
  .search-wrapper .search-left {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .search-wrapper .search-left {
    height: 300px;
    flex: 0 0 100%;
  }

  .sg-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sg-wrapper > div.card {
    width: 33%;
  }

  .no-views {
    float: none;
    display: block;
  }

  .search-filters-wrap {
    flex-wrap: wrap;
    padding: 15px;
  }

  .search-filters-wrap .mblSrchFrm {
    display: none;
  }

  .search-filters-wrap > div {
    width: 33%;
  }

  .search-bottom {
    display: none;
  }

  .search-left.map-hide,
  .search-right.map-hide .sg-wrapper {
    display: none;
  }

  .search-wrapper .search-right.map-hide {
    padding-bottom: 0;
  }

  .map-sort {
    display: flex;
    max-width: 215px;
    margin: 0 auto;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 99;
  }

  .map-toggle {
    width: 83px;
    height: 35px;
    padding: 7px;
    text-align: center;
    cursor: pointer;
  }

  .search-right .map-sort > div {
    min-width: 83px;
    width: auto;
    position: relative;
  }

  .search-right .map-sort > div.selectWrapper {
    min-width: 132px;
    border-left: 1px solid #000;
  }

  .ss-wrap > span.sort-icon {
    position: absolute;
    width: 20px;
    height: 34px;
    padding: 8px 5px;
  }

  .map-sort .selectWrapper .form-control {
    padding: 0 5px 0 25px;
    height: 35px;
    border-color: transparent;
  }

  .map-sort .selectWrapper::after {
    display: none;
  }

  .search-wrapper .search-right {
    padding-bottom: 45px;
  }

  .search-filters-wrap .btn {
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    min-width: 100%;
    height: 35px;
    padding: 8px;
  }

  .sr-top {
    padding: 9px 10px 5px;
  }

  .sr-top-head {
    font-size: 18px;
  }

  .search-wrapper .search-left {
    height: 500px;
    flex: 0 0 100%;
    position: relative;
  }

  .map-sort.list-toggle {
    max-width: 83px;
  }

  .map-sort.bottom {
    position: absolute;
    bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-filters-wrap {
    justify-content: space-between;
  }

  .search-wrapper .grid-card .nf-list > div {
    display: inline-block;
  }

  .search-wrapper .grid-card .nf-list > div + div {
    margin-top: 0;
  }

  .search-filters-wrap > div {
    margin: 4px 0;
  }

  .sg-wrapper > div.card {
    width: calc(33.3% - 2%);
    margin: 1%;
  }
}

@media (max-width: 767px) {
  .suitable {
    margin: 30px 0px !important;
  }

  .PropertyfilterButton .filterBtn {
    min-width: 100%;
  }

  .search-filters-wrap .PropertyfilterButtonGroup {
    flex-wrap: wrap;
    gap: 10px;
  }

  .search-filters-wrap .PropertyfilterButtonGroup .PropertyfilterButton {
    width: 48%;
  }

  .search-filters-wrap > div {
    width: 30%;
  }

  .search-filters-wrap > div:first-child {
    width: 100%;
    margin: 0;
  }

  .sg-wrapper > div.card {
    width: 100%;
  }

  .clsContainer {
    display: block;
  }

  .clsContainer .clsDrBtn .MuiButton-label {
    color: var(--primColor);
    text-transform: none;
  }

  .searchFilter {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .sg-wrapper > div.card {
    width: 49%;
  }
}

@media (max-width: 374px) {
  .search-filters-wrap > div {
    width: 28%;
  }

  .search-filters-wrap > div:first-child {
    width: 42%;
    margin: 0;
  }

  .search-filters-wrap > div:nth-child(4),
  .search-filters-wrap > div:nth-child(5) {
    width: 48%;
    margin: 0;
  }
}
