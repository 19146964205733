.gfn-wrap > * {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.pink-back {
  background: #e50e47 !important;
}

.green-back {
  background: #10c26d !important;
}

.uc-label {
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  color: #000;
  padding: 2px 5px;
  background: #fff;
  border-radius: 3px;
}

.upc-grid .grid-card-img {
  height: 250px;
}

.upc-grid .grid-bottom {
  bottom: 70px;
}

.upc-grid .schedule {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-radius: 0 0 3px 3px;
  padding: 10px;
  font-family: "Avenir-Medium";
  font-weight: normal;
  font-size: 15px;
  text-align: center;
  color: #000;
}

.sc-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sc-links a {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #9faebb !important;
}

.no-views {
  height: 22px;
  border-radius: 3px;
  background: #fff;
  font-family: "Avenir-Heavy";
  font-size: 13px;
  line-height: 16px;
  color: #000;
  float: right;
  padding: 3px 5px;
}

.buyChip {
  background: #000;
  text-align: center;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
  padding: 3px 10px;
  border-radius: 100px;
}

.sg-wrapper .image-gallery-bullets {
  top: auto;
  bottom: 28px;
}

.search-left .image-gallery-bullets .image-gallery-bullet,
.sg-wrapper .image-gallery-bullets .image-gallery-bullet {
  transform: scale(0.4);
  margin: 0 0px;
  background-color: #ccc;
  border: 1px solid #ccc;
}

.search-left .image-gallery-bullets .image-gallery-bullet.active,
.sg-wrapper .image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(0.7);
}
.listingType {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 5px;
  z-index: 2;
}
.listingType .assetType {
  border-radius: 25px;
  font-size: 13px;
  padding: 4px 15px;
  background-color: #000;
  color: #fff;
  text-transform: capitalize;
}
.featuredAuctions .featuedType {
  display: none;
}
.listingType .featuedType {
  border-radius: 25px;
  font-size: 13px;
  padding: 4px 15px;
  background-color: #70ad47;
  color: #fff;
}
.favoriteList {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
.upcomAuc .image-gallery-bullets .image-gallery-bullet {
  padding: 2px;
}
.noOfBids svg {
  font-size: 20px;
  margin-right: 5px;
}
.topMapFilterBox {
  position: sticky;
  top: 90px;
  z-index: 99;
  background: #fff;
  box-shadow: -10px 10px 8px -11px #e7e7e7;
  margin-top: 0px;
  padding-top: 5px;
}

.search-left .image-gallery-right-nav,
.sg-wrapper .image-gallery-right-nav {
  z-index: 99;
}

.search-left .image-gallery-slide-wrapper .image-gallery-icon,
.sg-wrapper .image-gallery-slide-wrapper .image-gallery-icon {
  opacity: 0;
}

.search-left .image-gallery-slide-wrapper:hover .image-gallery-icon,
.sg-wrapper .image-gallery-slide-wrapper:hover .image-gallery-icon {
  opacity: 1;
}

.sc-top-wrap {
  position: relative;
}

.sc-top-wrap .timer-class {
  position: relative;
  position: absolute;
  bottom: 0;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  width: 100%;
  border-radius: 0 0 10px 10px;
  text-align: center;
  z-index: 9;
}
