.syh-tcontainer-wrapper {
  background: #fff;
}

.syh-content {
  display: flex;
  max-width: 1420px;
  align-items: center;
  padding: 60px 0;
}

.syh-content > div {
  width: 50%;
}

.syh-content > div img {
  max-width: 100%;
}

.syh-content > div.syh-text-wrap {
  padding: 0 75px;
}

.syh-text-wrap h3 {
  font-weight: 400;
  font-size: 36px;
  line-height: 60.1px;
  color: #000;
  margin: 0 0 8px;
}

.syh-text-wrap p {
  color: #9faebb;
  font-size: 14px;
  line-height: 21.88px;
  margin-bottom: 0;
  font-weight: 500;
}

.syh-content:last-child {
  padding-bottom: 20px;
}

.have-container {
  padding: 10px 0;
}

@media (min-width: 768px) {
  .syh-column-reverse {
    flex-direction: row-reverse;
  }
}

.have-questions-wrapper {
  padding: 35px 65px;
  width: 100%;
  max-width: 954px;
  margin: 50px auto;
  background: #f2f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.have-container h3 {
  font-size: 32px;
  line-height: 43.71px;
  font-family: "Avenir-Light";
  font-weight: 400;
}

.have-container p {
  font-size: 14px;
  color: #000;
  line-height: 21px;
  margin: 0;
  max-width: 500px;
}

.have-container .btn-border {
  width: 210px;
  height: 44px;
  border-radius: 3px;
  background: transparent;
}

.scp-head {
  text-align: center;
  font-size: 48px;
  line-height: 65.57px;
  font-weight: 400;
  font-family: "Avenir-Light";
  margin: 40px auto 5px;
  color: #000;
}

.scp-note {
  max-width: 520px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.syh-cp-box {
  display: flex;
  max-width: 960px;
  margin: 34px auto;
  background: #fff;
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 5%);
  height: 400px;
  justify-content: center;
  align-items: center;
}

.syh-cp-box > div {
  width: 25%;
  padding: 15px;
  height: 100%;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
}

.syh-cp-box > div:first-child {
  background: #f8fafb;
  font-size: 24px;
  line-height: 33px;
  color: #000;
  font-weight: 400;
  font-family: "Avenir-Light";
}

.scp-title {
  font-size: 24px;
  line-height: 33px;
  color: #000;
  font-weight: 400;
  font-family: "Avenir-Light";
  text-align: center;
  width: 100%;
}

.cp-circle {
  width: 121px;
  height: 121px;
  border-radius: 50%;
  background: #f2f5f7;
  margin: 12px auto;
  display: block;
  position: relative;
}

.cp-circle img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 44px;
  margin: auto;
}

.scp-price {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 32px;
  line-height: 44px;
  color: var(--primColor);
  font-weight: normal;
}

.scp-descp {
  color: #595959;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
}

.scp-btn-wrap {
  position: relative;
  width: 100%;
  height: 44px;
  margin: 14px auto 0;
}

.scp-btn-wrap input[type="radio"] {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  opacity: 0;
  cursor: pointer;
}

.scp-btn-wrap input[type="radio"] ~ label {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  background: var(--primColor);
  font-size: 15px;
  padding: 14px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.syh-tcontainer-wrapper .hi-wrapper {
  padding: 10px 0 75px;
}

.syh-choose-plan {
  padding-bottom: 190px;
  background: #f2f5f7;
}

.why-included .scp-head {
  margin-top: -187px;
  font-size: 24px;
  line-height: 32.78px;
}

.sy-pb-wrap {
  display: flex;
  max-width: 340px;
  margin: 15px auto;
  justify-content: space-between;
  line-height: 21px;
  font-size: 14px;
  color: #595959;
}

.scheck-circle {
  width: 28px;
  display: inline-block;
  height: 28px;
  border-radius: 50%;
  vertical-align: middle;
}

.scheck-circle .fa {
  font-size: 16px;
  margin: 7px 6px;
  line-height: 16px;
  height: 16px;
  color: #fff;
}

.lgb-circle {
  background: #c2d1d9;
}

.bb-circle {
  background: var(--primColor);
}

.wi-box {
  max-width: 960px;
  margin: 0 auto -34px;
  background: #26222f;
  height: 34px;
  color: #fff;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
}

.why-included .syh-cp-box > div {
  padding: 0;
}

.why-included .syh-cp-box > div > div,
.fl-xs-list > div {
  width: 100%;
  height: 64px;
  padding: 25px 14px;
  line-height: 14px;
  font-size: 16px;
  border: 1px solid #e7edf0;
}

.why-included .syh-cp-box > div > div.wi-title {
  font-size: 18px;
  color: var(--primColor);
  text-align: center;
  font-family: "Avenir-Medium";
}

.why-included .syh-cp-box {
  height: auto;
  box-shadow: none;
}

.why-included .syh-cp-box > div > div:last-child,
.fl-xs-list > div:last-child {
  background: #fff;
  border: none;
  margin: 0;
}

.syh-main-wrapper .sls-head {
  margin-bottom: 18px;
  color: #000000;
  font-size: 24px;
}

.syh-main-wrapper .sls-form-note {
  font-size: 18px;
  max-width: 460px;
  line-height: 24.59px;
  color: #000000;
  text-align: center;
  margin: 0 auto;
}

.syh-form-wrapper {
  width: calc(100% - 30px);
  max-width: 460px;
  padding: 15px 45px 45px;
  background: #fff;
  margin: 50px auto;
}

.syh-form-wrapper h4 {
  font-size: 24px;
  text-align: center;
  line-height: 33px;
  color: #9faebc;
  margin: 0 0 12px;
}

.syh-form-wrapper form .form-group {
  margin: 0 0 10px !important;
}

.syh-form-wrapper label.pl-label {
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  font-weight: 400;
}

.syh-form-wrapper .selectWrapper {
  padding-right: 0;
}

.syh-form-wrapper .selectWrapper .form-control {
  padding-right: 30px;
}

.syf-row {
  margin: 0 -4.5px;
}

.syh-form-wrapper .selectWrapper::after {
  top: 0;
}

.syf-row > div {
  padding: 0 4.5px;
}

.syh-pt-10 {
  padding-top: 10px;
}

.view-sample {
  text-align: center;
}

.view-sample a {
  display: inline-block;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  margin: 10px auto 34px;
  color: #6320ee !important;
}

.syh-custom-radio {
  background: #f2f5f7;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  display: flex;
}

.syh-custom-radio > div {
  position: relative;
  width: 50%;
  padding: 2px;
}

.syh-custom-radio input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 26px;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.syh-custom-radio input[type="radio"] ~ span {
  font-size: 14px;
  line-height: 20px;
  padding: 3px;
  text-align: center;
  color: #9faebc;
  border-radius: 13px;
  display: block;
  height: 26px;
  font-weight: 800;
  font-family: "Avenir-" Medium "";
}

.syh-custom-radio input[type="radio"]:checked ~ span {
  background: #fff;
  color: var(--primColor);
}

.syh-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.syh-radio-group label {
  margin-bottom: 0;
}

.cblue-text {
  text-align: center;
  color: var(--primColor);
}

@media (max-width: 767px) {
  .syh-content {
    flex-wrap: wrap;
  }

  .syh-content > div {
    width: 100%;
  }

  .syh-content > div.syh-text-wrap {
    padding: 0 15px;
  }

  .syh-tcontainer-wrapper .hi-wrapper,
  .syh-content {
    padding: 15px 0;
  }

  .syh-hi-works .row .mb-5:last-child {
    margin-bottom: 0 !important;
  }

  .syh-text-wrap h3 {
    font-size: 24px;
    line-height: 1.67;
  }

  .syh-text-wrap p {
    margin-bottom: 25px;
  }

  .scp-head {
    font-size: 28px;
    line-height: 1.67;
  }

  .scp-note {
    font-size: 14px;
    line-height: 1.67;
  }

  .syh-cp-box {
    flex-wrap: wrap;
    height: auto;
  }

  .syh-cp-box > div {
    width: 100%;
    height: auto;
    position: relative;
  }

  .syh-cp-box > div:first-child {
    font-size: 16px;
    text-align: center;
  }

  .cp-circle {
    width: 75px;
    height: 75px;
    margin: auto;
    display: block;
    position: absolute;
    left: 10px;
    top: 12px;
  }

  .scp-price,
  .scp-title {
    text-align: left;
    padding-left: 90px;
  }

  .why-included .syh-cp-box > div > div,
  .fl-xs-list > div {
    padding: 8px;
    font-size: 12px;
    height: 42px;
  }

  .why-included .scp-head {
    margin-top: -208px;
  }

  .fl-wrapper {
    position: relative;
    padding-left: 145px;
    margin-top: 34px;
  }

  .fl-xs-list {
    position: absolute;
    left: 0;
    width: 145px;
  }

  .fl-inner-wrap {
    width: 100%;
    display: block;
    position: relative;
  }

  .why-included .syh-cp-box {
    margin: 0;
    padding-bottom: 45px;
    width: 450px;
    flex-wrap: nowrap;
  }

  .why-included .syh-cp-box > div {
    width: 33%;
  }

  .have-questions-wrapper {
    padding: 25px 15px;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .have-questions-wrapper > div {
    width: 100%;
    text-align: center;
  }

  .have-container .btn-border {
    margin: 15px auto 0;
  }

  .syh-form-wrapper {
    padding: 15px;
  }

  .syh-main-wrapper .sls-form-note {
    max-width: calc(100% - 30px);
  }

  .syh-form-wrapper h4 {
    font-size: 20px;
  }
}
